import { debounce } from "lodash";

import { studentService } from "../../../services";
import { useEffect, useState } from "react";
import { notification } from "antd";
import { retakeRequestsTableDataSourceMapper } from "../common/mapper";

export const useRetakeRequests = () => {
  //**** STATE ***//
  const [retakeRequests, setRetakeRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);

  const handleOnSearch = (event) => {
    setKeyword(event.target.value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchRequest(current);
  };
  const fetchRequest = debounce(async (currentPage) => {
    try {
      setLoading(true);
      const response = await studentService.getRetakeRequests(
        keyword,
        currentPage,
        pagination.pageSize
      );
      if (response) {
        setRetakeRequests(
          retakeRequestsTableDataSourceMapper(response.data.requests)
        );
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, 500);
  const handleReview = async (retakeRequestID, status) => {
    try {
      const response = await studentService.reviewRetakeRequest(
        retakeRequestID,
        status
      );
      if (response) {
        notification["success"]({
          message: "Update success!",
          description: "Update request successfully",
        });
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      notification["success"]({
        message: "Update failed!",
        description: "Issue while updating request",
      });
    }
  };

  useEffect(() => {
    fetchRequest();

    return () => {
      fetchRequest.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh]);

  return {
    retakeRequests,
    loading,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleReview,
  };
};
