import axios from "axios";
const crawlerUrl =
  process.env.REACT_APP_CRAWLER_API_URL || "http://localhost:3000/api/";

export const getCrawlingJobs = () => {
  return axios.get(`${crawlerUrl}crawling-jobs`);
};

export const triggerQsApiJob = () => {
  return axios.post(`${crawlerUrl}scrape-qs-api`);
};
export const triggerProgramDetailsHtmlJob = () => {
  return axios.post(`${crawlerUrl}scrape-program-details-html`);
};
export const triggerProgramsForUniversitiesOpenApiJob = (universities) => {
  return axios.post(`${crawlerUrl}programs-for-universities`, {
    universities,
  });
};
