import { useEffect, useState } from "react";

import { Input, InputNumber, Select } from "antd";

import { useCountryCodes } from "../../../contexts/countryCodes";
import Icon from "../Icon";

import "./index.css";
const { Option } = Select;

export const validatePhoneNumber = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("Please enter your phone number"));
  }

  const [countryCode, phoneNumber] = value.split(" ");

  if (!phoneNumber || !countryCode || phoneNumber.trim() === "") {
    return Promise.reject(new Error("Please enter a valid phone number"));
  }

  return Promise.resolve();
};

const PhoneInput = ({ form, value, fieldName = "phoneNumber" }) => {
  const { countryCodes } = useCountryCodes();
  const [countryCode, setCountryCode] = useState(""); // Default country code
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (value) {
      const [initialCode, ...initialPhone] = value.split(" ");
      setCountryCode(initialCode);
      setPhoneNumber(initialPhone.join("")); //remove spacing every each 3 digits
      form.setFieldsValue({ [fieldName]: value });
    }
  }, [value, form, fieldName]);

  const handleCountryCodeChange = (newCountryCode) => {
    setCountryCode(newCountryCode);
    form.setFieldsValue({ [fieldName]: `${newCountryCode} ${phoneNumber}` });
  };

  const handlePhoneNumberChange = (newPhoneNumber) => {
    setPhoneNumber(newPhoneNumber);
    form.setFieldsValue({ [fieldName]: `${countryCode} ${newPhoneNumber}` });
  };

  return (
    <Input.Group compact>
      <Select
        showSearch
        value={countryCode}
        className="input__country"
        onChange={handleCountryCodeChange}
        optionFilterProp="children"
      >
        {countryCodes?.map((x) => (
          <Option key={x.code} value={x.code}>
            <Icon width={20} loading="eager" src={`/flags/${x.image}`} />{" "}
            {x.code} ({x.country})
          </Option>
        ))}
      </Select>
      <InputNumber
        controls={false}
        className="input__country"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
    </Input.Group>
  );
};

export default PhoneInput;
