import { useState } from "react";
import { Flex, Form, notification, Typography } from "antd";

import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { useProducts } from "../../hooks/useProducts";
import { productTableColumns } from "../Table/columns/product";
import ProductModal from "./ProductModal";

function ProductManagement() {
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();

  const {
    products,
    productLoading,
    editingProduct,
    handleSelectProduct,
    handleEditProduct,
  } = useProducts();

  /**HANDLER */
  const handleOpenProductModal = (product) => {
    setOpenModal(true);
    handleSelectProduct(product);
    form.setFieldsValue(product);
  };
  const handleOnSubmit = async (newValues) => {
    try {
      await handleEditProduct({
        ...newValues,
        id: editingProduct.id,
      });

      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
      handleCloseModal();
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <ContentWrapper>
        <Flex justify="space-between">
          <Typography.Title className="content__header" level={3}>
            Product management
          </Typography.Title>
        </Flex>

        <CustomTable
          loading={productLoading}
          dataSource={products}
          columns={productTableColumns(handleOpenProductModal)}
        />
      </ContentWrapper>
      <ProductModal
        loading={productLoading}
        open={openModal}
        form={form}
        onFinish={handleOnSubmit}
        onCancel={handleCloseModal}
      />
    </>
  );
}

export default ProductManagement;
