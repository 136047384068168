import { Flex, Popconfirm, Typography } from "antd";

import { editIcon, rejectIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";
import { UNIVERSITY_TABLE_ACTIONS } from "../../../../../../common/constants/actions";

export const universityTableColumns = (onOpenModal, onDelete) => [
  {
    title: "University",
    key: "universityName",
    dataIndex: "university_name",
  },
  {
    title: "Campus",
    dataIndex: "campus_names",
    key: "campusNames",
    render: (_, { campus_names }) => {
      return campus_names?.map((x) => (
        <Flex vertica gap={10} l>
          <Typography.Text>{x}</Typography.Text>
        </Flex>
      ));
    },
  },
  {
    title: "Website",
    dataIndex: "uni_website",
    key: "universityWebsite",
  },
  {
    title: "Email",
    dataIndex: "uni_email",
    key: "universityEmail",
  },
  {
    title: "Regions",
    dataIndex: "regions",
    key: "regions",
    render: (_, { regions }) => {
      return regions?.map((x) => (
        <Flex gap={10} vertical>
          <Typography.Text>{x}</Typography.Text>
        </Flex>
      ));
    },
  },
  {
    title: "Countries",
    dataIndex: "countries",
    key: "countries",
    render: (_, { countries }) => {
      return countries?.map((x) => (
        <Flex vertical>
          <Typography.Text>{x}</Typography.Text>
        </Flex>
      ));
    },
  },
  {
    title: "Cities",
    dataIndex: "cities",
    key: "cities",
    render: (_, { cities }) => {
      return cities?.map((x) => (
        <Flex vertical>
          <Typography.Text>{x}</Typography.Text>
        </Flex>
      ));
    },
  },
  {
    title: "Rankings position",
    dataIndex: "rankings_position",
    key: "rankingsPosition",
  },
  {
    title: "Action",
    key: "action",
    render: (_, university) => {
      return (
        <Flex align="center" gap={10}>
          <Icon
            className="logo__action"
            preview={false}
            src={editIcon}
            onClick={() =>
              onOpenModal(university, UNIVERSITY_TABLE_ACTIONS.EDIT_UNIVERSITY)
            }
          />
          <Popconfirm
            title="Delete this record?"
            onConfirm={() => onDelete(university.id)}
          >
            <Icon className="logo__action" preview={false} src={rejectIcon} />
          </Popconfirm>
        </Flex>
      );
    },
  },
];
