import { Flex, Typography } from "antd";

import { Icon } from "../../../../../../../common/components";
import { peopleIcon } from "../../../../../../../assets/icons";

function AdvisorInfo({ advisorName }) {
  return (
    <Flex gap={16}>
      <Icon src={peopleIcon} />
      <Typography.Text strong>Advisor: {advisorName}</Typography.Text>
    </Flex>
  );
}

export default AdvisorInfo;
