import { Space, Typography } from "antd";
import UserInfo from "../../../UserInfo";
import { Icon } from "../../../../../../common/components";
import { calendarIcon, editIcon } from "../../../../../../assets/icons";
import { ADVISOR_TABLE_ACTIONS } from "../../../../../../common/constants/actions";

export const advisorTableColumns = (onOpenModal) => [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (_, { email, profileImage }) => {
      return <UserInfo profileImage={profileImage} userName={email} />;
    },
  },
  {
    title: "English name",
    dataIndex: "englishName",
    key: "englishName",
  },
  {
    title: "Biography",
    key: "englishBio",
    dataIndex: "englishBio",
    render: (_, { englishBio }) => {
      return <Typography.Text>{englishBio} </Typography.Text>;
    },
  },
  {
    title: "Time slot / day",
    key: "sessionQuota",
    dataIndex: "sessionQuota",
  },
  {
    title: "Action",
    key: "action",
    render: (_, advisor) => {
      return (
        <Space size="small">
          <>
            <Icon
              className="logo__action"
              preview={false}
              src={editIcon}
              onClick={() =>
                onOpenModal(advisor, ADVISOR_TABLE_ACTIONS.EDIT_INFO)
              }
            />

            <Icon
              className="logo__action"
              preview={false}
              src={calendarIcon}
              onClick={() =>
                onOpenModal(advisor, ADVISOR_TABLE_ACTIONS.TIME_SLOT)
              }
            />
          </>
        </Space>
      );
    },
  },
];
