import { Flex, Layout } from "antd";
import "./index.css";

const { Content } = Layout;

function ContentWrapper({ children }) {
  return (
    <Content className="content__wrapper">
      <Flex gap={24} vertical>
        {children}
      </Flex>
    </Content>
  );
}

export default ContentWrapper;
