import { Tag, Typography } from "antd";

const CRAWLING_JOB_STATUS = {
  PROCESSING: "Processing",
  FAILED: "Failed",
  SUCCESSFUL: "Successful",
};

export const crawlingJobTableColumns = () => [
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_, { status }) => {
      let color = "geekblue";
      if (status === CRAWLING_JOB_STATUS.SUCCESSFUL) color = "green";
      if (status === CRAWLING_JOB_STATUS.FAILED) color = "red";
      return (
        <Tag color={color} key={status}>
          {status.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Type",
    key: "type",
    dataIndex: "type",
    render: (_, record) => {
      return <Typography.Text>{record.type}</Typography.Text>;
    },
  },
  {
    title: "Programs file name",
    key: "programsFileName",
    dataIndex: "programsFileName",
    render: (_, record) => {
      return <Typography.Text>{record.programsFileName}</Typography.Text>;
    },
  },
  {
    title: "Universities file name",
    key: "universitiesFileName",
    dataIndex: "universitiesFileName",
    render: (_, record) => {
      return <Typography.Text>{record.universitiesFilename}</Typography.Text>;
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "Created At",
    render: (_, record) => {
      return <Typography.Text>{record.createdAt}</Typography.Text>;
    },
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "Updated At",
    render: (_, record) => {
      return <Typography.Text>{record.updatedAt}</Typography.Text>;
    },
  },
];
