import { Flex, Typography } from "antd";

import AdvisorInfo from "./AdvisorInfo";
import "./index.css";
function Header({ advisorName, totalSessions }) {
  return (
    <Flex className="header__container" justify="space-between">
      <AdvisorInfo advisorName={advisorName} />
      {totalSessions && (
        <Typography.Text>Total: {totalSessions} session(s)</Typography.Text>
      )}
    </Flex>
  );
}

export default Header;
