import { useEffect, useState } from "react";
import { crawlingJobsTableDataSourceMapper } from "../common/mapper";
import { crawlingJobService } from "../../../services";
import { notification } from "antd";

export const useCrawlingJobs = () => {
  /**** STATE ***/
  const [crawlingJobs, setCrawlingJobs] = useState([]);
  const [crawlingJobsLoading, setCrawlingJobsLoading] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const isAllowedToTrigger = crawlingJobs?.every(
    (x) => x.status !== "Processing"
  );
  /** HANDLER*/
  const fetchCrawlingJobs = async (currentPage) => {
    try {
      setCrawlingJobsLoading(true);
      const response = await crawlingJobService.getCrawlingJobs();

      if (response) {
        setCrawlingJobs(
          crawlingJobsTableDataSourceMapper(response.data.crawlingJobs)
        );
      }
    } catch (error) {
    } finally {
      setCrawlingJobsLoading(false);
    }
  };
  const handleTriggerApiCrawling = async () => {
    if (!isAllowedToTrigger) {
      notification["error"]({
        message: "Action failed!",
        description: "There is already running job",
      });
      return;
    }

    try {
      await crawlingJobService.triggerQsApiJob();
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  const handleTriggerHtmlCrawling = async () => {
    if (!isAllowedToTrigger) {
      notification["error"]({
        message: "Action failed!",
        description: "There is already running job",
      });
      return;
    }
    try {
      await crawlingJobService.triggerProgramDetailsHtmlJob();
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  const handleTriggerOpenAICrawling = async (universities) => {
    if (!isAllowedToTrigger) {
      notification["error"]({
        message: "Action failed!",
        description: "There is already running job",
      });
      return;
    }

    try {
      await crawlingJobService.triggerProgramsForUniversitiesOpenApiJob(
        universities
      );
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  const handleRefresh = () => {
    setNeedRefresh((prev) => !prev);
  };
  useEffect(() => {
    fetchCrawlingJobs();
  }, [needRefresh]);

  return {
    crawlingJobs,
    crawlingJobsLoading,
    handleTriggerApiCrawling,
    handleTriggerHtmlCrawling,
    handleTriggerOpenAICrawling,
    handleRefresh,
  };
};
