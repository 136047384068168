import { Flex, Layout, Typography } from "antd";

import SearchBar from "../../../../common/components/SearchBar";
import { useRetakeRequests } from "../../hooks/useRetakeRequests";
import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { retakeRequestTableColumns } from "../Table/columns";

const { Content } = Layout;
const { Title } = Typography;

function RetakeRequest() {
  const {
    retakeRequests,
    loading,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleReview,
  } = useRetakeRequests();

  return (
    <Content>
      <ContentWrapper>
        <Flex justify="space-between">
          <Title className="content__header" level={3}>
            Retake request
          </Title>
        </Flex>

        <SearchBar
          placeholder="Search by phone number/ name"
          value={keyword}
          onSearch={handleOnSearch}
        />
        <CustomTable
          loading={loading}
          dataSource={retakeRequests}
          columns={retakeRequestTableColumns(handleReview)}
          pagination={pagination}
          onPagination={handlePagination}
        />
      </ContentWrapper>
    </Content>
  );
}

export default RetakeRequest;
