import { Navigate } from "react-router-dom";
import { useUser } from "../../contexts/user";

import { Spin } from "antd";

function ProtectedRoute({ children }) {
  const { isAuthenticated, isLoading } = useUser();
  if (isLoading) return <Spin className="spinner" />;
  if (!isAuthenticated && !isLoading) return <Navigate to="/" />;
  return children;
}

export default ProtectedRoute;
