import { Flex, Space, Typography } from "antd";
import moment from "moment";

import { editIcon, viewIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";
import { BOOKED_SESSION_ACTIONS } from "../../../../../../common/constants/actions";
import UserInfo from "../../../UserInfo";
import { formatTableDate } from "../../../../../../common/utils/date";

export const bookedSessionTableColumns = (onOpenModal) => [
  {
    title: "Advisor",
    dataIndex: "advisor",
    key: "advisor",
    render: (_, { advisor }) => {
      return (
        <UserInfo
          profileImage={advisor.profileImage}
          userName={advisor.email}
        />
      );
    },
  },
  {
    title: "Student",
    dataIndex: "student",
    key: "student",
    render: (_, { student }) => {
      return <UserInfo userName={student.email} />;
    },
  },
  {
    title: "Meeting Link",
    dataIndex: "googleMeetUrl",
    key: "googleMeetUrl",
    render: (_, { googleMeetUrl }) => {
      return <a href={googleMeetUrl}>{googleMeetUrl}</a>;
    },
  },
  {
    title: "Start Time",
    key: "startDate",
    dataIndex: "startDate",
    render: (_, { startDate }) => {
      return (
        <Typography.Text>
          {formatTableDate(moment(startDate).toDate())}{" "}
        </Typography.Text>
      );
    },
  },
  {
    title: "Recording Link",
    key: "recordingLink",
    dataIndex: "recordingLink",
    render: (_, { recordingUrls }) => (
      <Flex vertical gap={5}>
        {recordingUrls?.map((x) => (
          <a key={x} href={x}>
            {x}
          </a>
        ))}
      </Flex>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => {
      return (
        <Space size="small">
          <>
            <Icon
              className="logo__action"
              preview={false}
              src={editIcon}
              onClick={() =>
                onOpenModal(record, BOOKED_SESSION_ACTIONS.EDIT_NOTE)
              }
            />

            <Icon
              className="logo__action"
              preview={false}
              src={viewIcon}
              onClick={() =>
                onOpenModal(record, BOOKED_SESSION_ACTIONS.VIEW_INFO)
              }
            />
          </>
        </Space>
      );
    },
  },
];
