import { DatePicker, Form, Input, Modal, Radio, Select } from "antd";

import Button, { BUTTON_TYPE } from "../../../../../common/components/Button";
import "./index.css";
import { Icon } from "../../../../../common/components";
import { useCountryCodes } from "../../../../../contexts/countryCodes";
function EditStudentModal({
  loading,
  form,
  open,
  initialValues,
  onCancel,
  onFinish,
}) {
  const { countryCodes } = useCountryCodes();
  return (
    <Modal title="Edit user info" open={open} footer={null} onCancel={onCancel}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        afterClose={() => form.resetFields()}
      >
        <Form.Item name="phoneNumber" label="WhatsApp account">
          <Input disabled placeholder="input your first name" />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please input your first name!",
            },
          ]}
        >
          <Input placeholder="input your first name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please input your first name!",
            },
          ]}
        >
          <Input placeholder="input your last name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email",
            },
          ]}
        >
          <Input placeholder="input your email" />
        </Form.Item>

        <Form.Item
          rules={[{ required: true }]}
          name="dateOfBirth"
          label="Birthday"
        >
          <DatePicker />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="Gender" name="gender">
          <Radio.Group>
            <Radio value="Male"> Male </Radio>
            <Radio value="Female"> Female </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="Country" name="country">
          <Select showSearch optionFilterProp="children">
            {countryCodes?.map((x) => (
              <Select.Option key={x.code} value={x.country}>
                <Icon width={20} loading="eager" src={`/flags/${x.image}`} />{" "}
                {x.code} ({x.country})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            type={BUTTON_TYPE.DEFAULT}
            style={{ float: "inline-end" }}
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditStudentModal;
