import axiosClient from "../base";

export const getPrograms = (
  keyword,
  page = 1,
  limit = 10,
  showManuallyAdded = false
) => {
  return axiosClient.get("programs/admin", {
    params: {
      keyword,
      page,
      limit,
      manuallyAdded: showManuallyAdded,
    },
  });
};

export const updateProgram = (
  id,
  major,
  program,
  university_name,
  study_destination,
  apply_study_level,
  overview,
  program_attributes,
  additional_info,
  admission_requirements
) => {
  return axiosClient.put("programs", {
    id,
    major,
    program,
    university_name,
    study_destination,
    apply_study_level,
    overview,
    program_attributes,
    additional_info,
    admission_requirements,
  });
};

export const deleteProgram = (id) => {
  return axiosClient.delete("programs", {
    data: id,
  });
};

export const createProgram = (
  major,
  program,
  university_name,
  study_destination,
  apply_study_level,
  overview,
  program_attributes,
  additional_info,
  admission_requirements
) => {
  return axiosClient.post("programs", {
    major,
    program,
    university_name,
    study_destination,
    apply_study_level,
    overview,
    program_attributes,
    additional_info,
    admission_requirements,
  });
};
