import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import ProtectedRoute from "./ProtectedRoute";

import { createBrowserRouter } from "react-router-dom";
import { MajorsProvider } from "../contexts/majors";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <MajorsProvider>
          <Dashboard />
        </MajorsProvider>
      </ProtectedRoute>
    ),
  },
]);
