import { Button as ButtonAntd } from "antd";
import "./index.css";

export const BUTTON_TYPE = {
  PRIMARY: "primary",
  DEFAULT: "default",
  TEXT: "text",
  LINK: "link",
  DASHED: "dashed",
};
function Button({
  type = BUTTON_TYPE.DEFAULT,
  className,
  children,
  onClick,
  ...props
}) {
  return (
    <ButtonAntd
      {...props}
      type={type}
      onClick={onClick}
      className={`custom__btn ${className}`}
    >
      {children}
    </ButtonAntd>
  );
}

export default Button;
