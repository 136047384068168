import { useState } from "react";

import { Checkbox, Flex, Form, notification, Typography } from "antd";

import { plusIcon } from "../../../../assets/icons";
import ContentWrapper from "../ContentWrapper";
import { Button, Icon } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";
import SearchBar from "../../../../common/components/SearchBar";
import CustomTable from "../Table";
import { UNIVERSITY_TABLE_ACTIONS } from "../../../../common/constants/actions";
import { checkHasChanges } from "../../../../common/utils/common";
import { useUniversities } from "../../hooks/useUniversities";
import { universityTableColumns } from "../Table/columns/university";
import UniversityModal from "./Modal";

function UniversityManagement() {
  /** STATE */
  const {
    editingUniversity,
    universities,
    universityLoading,
    keyword,
    pagination,
    showManuallyAdded,
    handlePagination,
    handleOnSearch,
    handleCreateUniversity,
    handleEditUniversity,
    handleDeleteUniversity,
    handleSelectUniversity,
    handleManuallyAddedChange,
  } = useUniversities();

  const [form] = Form.useForm();
  const [modal, setModal] = useState({
    isOpen: false,
    action: null,
  });

  const getFieldsToCompare = () => {
    const fieldsToCompare = { ...editingUniversity };
    delete fieldsToCompare.university_url;
    delete fieldsToCompare.rank_field;
    delete fieldsToCompare.qs_star;
    delete fieldsToCompare.logo;
    delete fieldsToCompare.id;

    return fieldsToCompare;
  };
  /** HANDLER */
  const handleOpenUniversityModal = (university, action) => {
    setModal({ isOpen: true, action });
    handleSelectUniversity(university);
    switch (action) {
      case UNIVERSITY_TABLE_ACTIONS.CREATE_UNIVERSITY:
        break;
      case UNIVERSITY_TABLE_ACTIONS.EDIT_UNIVERSITY:
        form.setFieldsValue(university);
        break;

      default:
        break;
    }
  };
  const handleCloseModal = () => {
    setModal({ isOpen: false });
    form.resetFields();
  };

  const handleOnSubmit = async (newValues) => {
    try {
      switch (modal.action) {
        case UNIVERSITY_TABLE_ACTIONS.CREATE_UNIVERSITY:
          await handleCreateUniversity(newValues);
          handleCloseModal();
          break;

        case UNIVERSITY_TABLE_ACTIONS.EDIT_UNIVERSITY:
          if (!checkHasChanges(getFieldsToCompare(), newValues)) return;
          await handleEditUniversity({
            id: editingUniversity.id,
            ...newValues,
          });
          handleCloseModal();
          break;

        default:
          break;
      }
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };

  return (
    <>
      <ContentWrapper>
        <Flex justify="space-between">
          <Typography.Title className="content__header" level={3}>
            University
          </Typography.Title>
          {showManuallyAdded && (
            <Button
              type={BUTTON_TYPE.PRIMARY}
              icon={<Icon width={16} height={16} src={plusIcon} />}
              onClick={() =>
                handleOpenUniversityModal(
                  null,
                  UNIVERSITY_TABLE_ACTIONS.CREATE_UNIVERSITY
                )
              }
            >
              University
            </Button>
          )}
        </Flex>
        <Flex justify="space-between" align="center">
          <SearchBar
            placeholder="Search by university name."
            value={keyword}
            onSearch={handleOnSearch}
          />
          <Checkbox
            value={showManuallyAdded}
            onChange={handleManuallyAddedChange}
          >
            Manually Added
          </Checkbox>
        </Flex>

        <CustomTable
          loading={universityLoading}
          dataSource={universities}
          pagination={pagination}
          columns={universityTableColumns(
            handleOpenUniversityModal,
            handleDeleteUniversity
          )}
          onPagination={handlePagination}
        />
      </ContentWrapper>

      <UniversityModal
        loading={universityLoading}
        action={modal.action}
        form={form}
        open={modal.isOpen}
        onCancel={handleCloseModal}
        onFinish={handleOnSubmit}
      />
    </>
  );
}

export default UniversityManagement;
