import { useEffect, useState } from "react";

import { notification } from "antd";

import { debounce } from "lodash";
import { programService } from "../../../services";
import { universityService } from "../../../services";

export const usePrograms = () => {
  //**** STATE ***//
  const [programs, setPrograms] = useState([]);
  const [universitiesOptions, setUniversitiesOptions] = useState([]);
  const [universityLoading, setUniversityLoading] = useState(false);
  const [programLoading, setProgramLoading] = useState(false);
  const [editingProgram, setEditingProgram] = useState(null);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);
  const [showManuallyAdded, setShowManuallyAdded] = useState(false);

  const handleOptionOnSearch = debounce((keyword) => {
    if (keyword.trim().length < 3) return;
    fetchUniversitiesOptions(keyword);
  }, 500);
  const handleManuallyAddedChange = (event) => {
    setShowManuallyAdded(event.target.checked);
  };
  const handleOnSearch = (event) => {
    const value = event.target.value;
    setKeyword(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchPrograms(current);
  };

  const handleSelectProgram = (program) => {
    setEditingProgram(program);
  };
  const fetchPrograms = debounce(async (currentPage) => {
    try {
      setProgramLoading(true);
      const response = await programService.getPrograms(
        keyword,
        currentPage,
        pagination.pageSize,
        showManuallyAdded
      );
      if (response) {
        setPrograms(response.data.programs);
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setProgramLoading(false);
    }
  }, 1000);

  const fetchUniversitiesOptions = async (keyword) => {
    try {
      setUniversityLoading(true);
      const response = await universityService.getUniversities(
        keyword,
        1,
        null
      );

      if (response) {
        setUniversitiesOptions(response.data.universities);
      }
    } catch (error) {
    } finally {
      setUniversityLoading(false);
    }
  };

  const handleCreateProgram = async ({
    major,
    program,
    university_name,
    study_destination,
    apply_study_level,
    overview,
    program_attributes,
    additional_info,
    admission_requirements,
  }) => {
    try {
      setProgramLoading(true);
      const response = await programService.createProgram(
        major,
        program,
        university_name,
        study_destination,
        apply_study_level,
        overview,
        program_attributes,
        additional_info,
        admission_requirements
      );
      if (response) {
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      throw error;
    } finally {
      setProgramLoading(false);
    }
  };

  const handleEditProgram = async ({
    id,
    major,
    program,
    university_name,
    study_destination,
    apply_study_level,
    overview,
    program_attributes,
    additional_info,
    admission_requirements,
  }) => {
    try {
      setProgramLoading(true);
      const response = await programService.updateProgram(
        id,
        major,
        program,
        university_name,
        study_destination,
        apply_study_level,
        overview,
        program_attributes,
        additional_info,
        admission_requirements
      );
      if (response) {
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      throw error;
    } finally {
      setProgramLoading(false);
    }
  };

  const handleDeleteProgram = async (id) => {
    try {
      const response = await programService.deleteProgram(id);
      if (response) {
        setNeedRefresh((prev) => !prev);
        notification["success"]({
          message: "Success!",
          description: "Performing action successfully",
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  useEffect(() => {
    fetchPrograms(pagination.current);

    return () => {
      fetchPrograms.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh, showManuallyAdded]);

  return {
    universityLoading,
    universitiesOptions,
    editingProgram,
    programs,
    programLoading,
    pagination,
    keyword,
    showManuallyAdded,
    handleOnSearch,
    handlePagination,
    handleCreateProgram,
    handleEditProgram,
    handleDeleteProgram,
    handleSelectProgram,
    handleManuallyAddedChange,
    handleOptionOnSearch,
  };
};
