import { Popconfirm, Space, Tag, Tooltip, Typography } from "antd";

import { NULL_VALUE } from "..";
import { deactivateIcon, editIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";
import { ACCOUNT_STATUS } from "../../../../../../common/constants/accountStatus";
import { STUDENT_TABLE_ACTIONS } from "../../../../../../common/constants/actions";

export const studentTableColumns = (onConfirm, onOpenModal) => [
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
    render: (_, record) => {
      return (
        <Typography.Text strong>
          {record.firstName ? record.fullName : NULL_VALUE}
        </Typography.Text>
      );
    },
  },
  {
    title: "WhatsApp account",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (_, { email }) => {
      return <Typography.Text>{email || NULL_VALUE} </Typography.Text>;
    },
  },
  {
    title: "Score",
    key: "score",
    dataIndex: "score",
    render: (_, { score }) => {
      return <Typography.Text>{score || NULL_VALUE} </Typography.Text>;
    },
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_, { status }) => {
      let color = "geekblue";
      if (status === ACCOUNT_STATUS.ACTIVE) color = "green";
      if (status === ACCOUNT_STATUS.DELETED) color = "red";
      return (
        <Tag color={color} key={status}>
          {status.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => {
      return (
        <Space size="small">
          {record.status !== ACCOUNT_STATUS.DELETED && (
            <>
              <Popconfirm
                title="User deactivate"
                description="Are you sure to deactivate this user?"
                onConfirm={() => onConfirm(record.key)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title={STUDENT_TABLE_ACTIONS.DEACTIVATE_STUDENT}>
                  <Icon className="logo__action" src={deactivateIcon} />
                </Tooltip>
              </Popconfirm>
              <Tooltip title={STUDENT_TABLE_ACTIONS.EDIT_INFO}>
                <Icon
                  className="logo__action"
                  src={editIcon}
                  onClick={() =>
                    onOpenModal(STUDENT_TABLE_ACTIONS.EDIT_INFO, record)
                  }
                />
              </Tooltip>
            </>
          )}
        </Space>
      );
    },
  },
];
