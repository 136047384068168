import { Space } from "antd";

import { editIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";
import { ACCOUNT_STATUS } from "../../../../../../common/constants/accountStatus";

export const productTableColumns = (onOpenModal) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Features",
    dataIndex: "features",
    key: "features",
    render: (_, { features }) => (
      <div dangerouslySetInnerHTML={{ __html: features }} />
    ),
  },
  {
    title: "Price in USD",
    dataIndex: "priceInUSD",
    key: "priceInUSD",
  },
  {
    title: "Price in Jordan",
    dataIndex: "priceInJordan",
    key: "priceInJordan",
  },
  {
    title: "Total sessions",
    key: "totalSessions",
    dataIndex: "totalSessions",
  },
  {
    title: "Total job trial exps",
    key: "totalJobTrialExps",
    dataIndex: "totalJobTrialExps",
  },
  {
    title: "Updated at",
    key: "updatedAt",
    dataIndex: "updatedAt",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => {
      return (
        <Space size="small">
          {record.status !== ACCOUNT_STATUS.DELETED && (
            <>
              <Icon
                clickable
                src={editIcon}
                onClick={() => onOpenModal(record)}
              />
            </>
          )}
        </Space>
      );
    },
  },
];
