import { useState } from "react";

import { Flex, Image, Layout, Menu } from "antd";

import { logo } from "../../assets/icons";
import { useUser } from "../../contexts/user";
import AdvisorManagement from "./components/AdvisorManagement";
import BookedSessions from "./components/BookedSessionManagement";
import CompanyManagement from "./components/CompanyManagement";
import JobApplication from "./components/JobApplicationManagement";
import JobTrialManagement from "./components/JobTrialManagement";
import { MENU_KEY, menuItems } from "./components/MenuItems";
import ProgramManagement from "./components/ProgramManagement";
import RetakeRequest from "./components/RetakeRequestManagement";
import TimeSlotManagement from "./components/TimeSlotManagement";
import UniversityManagement from "./components/UniversityManagement";
import UserManagement from "./components/UserManagement";
import CrawlingManagement from "./components/CrawlingManagement";
import ProductManagement from "./components/ProductManagement";
import Dropdown from "./components/Dropdown";

import "./index.css";

const { Sider, Header } = Layout;
function Dashboard() {
  const [chosenMenu, setChosenMenu] = useState(null);

  const { user } = useUser();
  const renderContent = () => {
    switch (chosenMenu) {
      case MENU_KEY.STUDENTS:
        return <UserManagement />;
      case MENU_KEY.ADVISORS:
        return <AdvisorManagement />;
      case MENU_KEY.COMPANIES:
        return <CompanyManagement />;
      case MENU_KEY.JOB_TRIALS:
        return <JobTrialManagement />;
      case MENU_KEY.RETAKE:
        return <RetakeRequest />;
      case MENU_KEY.TIME_SLOTS:
        return <TimeSlotManagement />;
      case MENU_KEY.BOOKED_SESSIONS:
        return <BookedSessions />;
      case MENU_KEY.JOB_APPLICATION:
        return <JobApplication />;
      case MENU_KEY.UNIVERSITIES:
        return <UniversityManagement />;
      case MENU_KEY.PROGRAMS:
        return <ProgramManagement />;
      case MENU_KEY.CRAWLING_JOBS:
        return <CrawlingManagement />;
      case MENU_KEY.PRODUCTS:
        return <ProductManagement />;
      default:
        break;
    }
  };
  const handleSwitchMenu = ({ key }) => {
    setChosenMenu(key);
  };

  return (
    <Layout className="dashboard__container">
      <Sider className="sider__wrapper" width="15%">
        <div className="logo">
          <Image
            preview={false}
            src={logo}
            onClick={() => window.location.reload()}
          />
        </div>

        <Menu
          onClick={handleSwitchMenu}
          defaultSelectedKeys={[chosenMenu]}
          defaultOpenKeys={[chosenMenu]}
          mode="inline"
          items={menuItems(user.userType)}
        />
      </Sider>

      <Layout>
        <Header className="header">
          <Flex justify="end">
            <Dropdown />
          </Flex>
        </Header>

        {renderContent()}
      </Layout>
    </Layout>
  );
}

export default Dashboard;
