import moment from "moment";
import { GENDER } from "../../../common/constants/gender";
import { formatTableDate } from "../../../common/utils/date";
import { formatPhoneNumber } from "../../../common/utils/phoneNumber";

export const studentTableDataSourceMapper = (students) => {
  return students.map((s) => ({
    ...s,
    key: s.id,
    phoneNumber: `(${s.countryCode}) ${formatPhoneNumber(s.whatsAppNumber)}`,
    fullName: `${s.firstName} ${s.lastName}`,
    status: s.status,
    email: s.email,
    dateOfBirth: moment(s.dateOfBirth),
    gender: s.gender || GENDER.MALE,
    score: s.score,
  }));
};

export const retakeRequestsTableDataSourceMapper = (requests) => {
  return requests.map((r) => ({
    ...r,
    key: r.id,
    phoneNumber: `(${r.student.countryCode}) ${formatPhoneNumber(
      r.student.whatsAppNumber
    )}`,
    fullName:
      r.student.firstName && `${r.student.firstName} ${r.student.lastName}`,
    email: r.student.email,
    // score: r.student.score,
    createdAt: formatTableDate(moment(r.createdAt).toDate()),
    numberOfAttempts: r.student.numberOfAttempts,
  }));
};

export const sessionMapper = (src) => ({
  ...src,
  startDate: moment.utc(src.startDate).local().format(),
  endDate: moment.utc(src.endDate).local().format(),
});

export const sessionsByDatesMapper = (sessions) => {
  return sessions.reduce((acc, session) => {
    const formattedSession = sessionMapper(session);
    const formattedDate = formattedSession.startDate.split("T")[0];

    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }

    acc[formattedDate].push(formattedSession);

    return acc;
  }, {});
};

export const bookedSessionsMapper = (src) =>
  src.map((x) => ({
    ...x,
  }));

export const companiesTableDataSourceMapper = (companies) => {
  return companies.map((x) => ({
    ...x,
    profileImage: `${x.profileImage}?unique=${new Date().getTime()}`,
    phoneNumber: `${x.countryCode} ${x.phone}`,
  }));
};

export const jobApplicationsTableDataSourceMapper = (jobTrials) => {
  return jobTrials.map((item) => ({
    ...item,
    createdAt: formatTableDate(moment(item.createdAt).toDate()),
  }));
};

export const crawlingJobsTableDataSourceMapper = (jobTrials) => {
  return jobTrials.map((item) => ({
    ...item,
    updatedAt: formatTableDate(moment(item.updatedAt).toDate()),
    createdAt: formatTableDate(moment(item.createdAt).toDate()),
  }));
};

export const productsTableDataSourceMapper = (products) => {
  return products.map((item) => ({
    ...item,
    updatedAt: formatTableDate(moment(item.updatedAt).toDate()),
    createdAt: formatTableDate(moment(item.createdAt).toDate()),
  }));
};
