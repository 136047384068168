import { Form, Input, InputNumber, Modal, Select } from "antd";

import { Icon } from "../../../../../common/components";
import Button, { BUTTON_TYPE } from "../../../../../common/components/Button";
import { ADVISOR_TABLE_ACTIONS } from "../../../../../common/constants/actions";
import { useCountryCodes } from "../../../../../contexts/countryCodes";
import AvailableTimeSlot from "../../TimeSlotManagement/AvailableTimeSlot";

const { TextArea } = Input;
function AdvisorModal({
  loading,
  open,
  action,
  form,
  onFinish,
  onCancel,
  openDrawer,
  selectedDate,
  sessionsByDates,
  onAddSession,
  onRemoveSession,
  onClose,
  onSelect,
  onPanelChange,
}) {
  const { countryCodes } = useCountryCodes();

  /** LOCAL STATE */
  const modalWith = action === ADVISOR_TABLE_ACTIONS.TIME_SLOT ? 1200 : 520;
  const renderModalContent = () => {
    if (action === ADVISOR_TABLE_ACTIONS.TIME_SLOT)
      return (
        <AvailableTimeSlot
          openDrawer={openDrawer}
          selectedDate={selectedDate}
          sessionsByDates={sessionsByDates}
          onAddSession={onAddSession}
          onRemoveSession={onRemoveSession}
          onSubmit={onFinish}
          onClose={onClose}
          onSelect={onSelect}
          onPanelChange={onPanelChange}
        />
      );

    return (
      <Form form={form} onFinish={(value) => onFinish(value, action)}>
        <Form.Item
          label="Country code"
          name="countryCode"
          rules={[
            {
              required: true,
              message: "Please select country code!",
            },
          ]}
        >
          <Select
            disabled={action === ADVISOR_TABLE_ACTIONS.EDIT_INFO}
            showSearch
            optionFilterProp="children"
          >
            {countryCodes?.map((x) => (
              <Select.Option key={x.code} value={x.code}>
                <Icon width={20} loading="eager" src={`/flags/${x.image}`} />{" "}
                {x.code} ({x.country})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="whatsAppNumber"
          label="WhatsApp number"
          rules={[
            {
              required: true,
              message: "Please input whatsAppNumber!",
            },
          ]}
        >
          <Input
            disabled={action === ADVISOR_TABLE_ACTIONS.EDIT_INFO}
            type="number"
            placeholder="Enter whatsAppNumber"
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input advisor's email",
            },
          ]}
        >
          <Input
            disabled={action === ADVISOR_TABLE_ACTIONS.EDIT_INFO}
            placeholder="input your advisor's email"
          />
        </Form.Item>

        <Form.Item
          name="englishName"
          label="English name"
          rules={[
            {
              required: true,
              message: "Please input advisor's English name!",
            },
          ]}
        >
          <Input placeholder="input advisor's English name" />
        </Form.Item>

        <Form.Item
          name="arabicName"
          label="Arabic name"
          rules={[
            {
              required: true,
              message: "Please input advisor's Arabic name!",
            },
          ]}
        >
          <Input placeholder="input advisor's Arabic name" />
        </Form.Item>

        <Form.Item
          name="englishBio"
          label="English biography"
          rules={[
            {
              required: true,
              message: "Please input advisor's English biography!",
            },
          ]}
        >
          <TextArea rows={4} placeholder="input advisor's English biography" />
        </Form.Item>
        <Form.Item
          name="arabicBio"
          label="Arabic biography"
          rules={[
            {
              required: true,
              message: "Please input advisor's Arabic biography!",
            },
          ]}
        >
          <TextArea rows={4} placeholder="input advisor's Arabic biography" />
        </Form.Item>
        <Form.Item name="sessionQuota" label="Total session(s)/ day">
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            type={BUTTON_TYPE.PRIMARY}
            style={{ float: "inline-end" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      width={modalWith}
      title={action}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default AdvisorModal;
