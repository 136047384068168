import { Image, Popconfirm, Space, Typography } from "antd";

import { NULL_VALUE } from "..";
import { approveIcon, rejectIcon } from "../../../../../../assets/icons";
import { REVIEW_STATUS } from "../../../../../../common/constants/reviewStatus";

export const retakeRequestTableColumns = (onReview) => [
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
    render: (_, record) => {
      return (
        <Typography.Text strong>
          {record.fullName || NULL_VALUE}
        </Typography.Text>
      );
    },
  },
  {
    title: "WhatsApp account",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (_, { email }) => {
      return <Typography.Text>{email || NULL_VALUE} </Typography.Text>;
    },
  },
  {
    title: "Requested at",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (_, { createdAt }) => {
      return <Typography.Text>{createdAt} </Typography.Text>;
    },
  },
  // {
  //   title: "Score",
  //   key: "score",
  //   dataIndex: "score",
  //   render: (_, { score }) => {
  //     return <Text>{score || NULL_VALUE} </Text>;
  //   },
  // },
  {
    title: "Number of attempts",
    key: "numberOfAttempts",
    dataIndex: "numberOfAttempts",
  },
  {
    title: "Action",
    key: "action",
    render: (_, { key: retakeRequestID }) => {
      return (
        <Space size="small">
          <>
            <Popconfirm
              title="Request approve"
              description="Are you sure to approve this request?"
              onConfirm={() =>
                onReview(retakeRequestID, REVIEW_STATUS.APPROVED)
              }
              okText="Yes"
              cancelText="No"
            >
              <Image
                className="logo__action"
                preview={false}
                src={approveIcon}
              />
            </Popconfirm>
            <Popconfirm
              title="Request reject"
              description="Are you sure to reject this request"
              onConfirm={() =>
                onReview(retakeRequestID, REVIEW_STATUS.REJECTED)
              }
              okText="Yes"
              cancelText="No"
            >
              <Image
                className="logo__action"
                preview={false}
                src={rejectIcon}
              />
            </Popconfirm>
          </>
        </Space>
      );
    },
  },
];
