import axiosClient from "../base";

export const getProducts = () => {
  return axiosClient.get("products");
};

export const updateProduct = ({
  id,
  title,
  features,
  priceInUSD,
  priceInJordan,
  // savings,
  totalSessions,
  totalJobTrialExps,
  isJobTrial,
}) => {
  return axiosClient.put(`products/${id}`, {
    title,
    features,
    priceInUSD,
    priceInJordan,
    totalSessions,
    totalJobTrialExps,
    isJobTrial,
  });
};
