import { Calendar } from "antd";

import TimeRange from "../TimeRange";
import TimeSlotsDrawer from "../Drawer";
import "./index.css";

function AvailableTimeSlot({
  openDrawer,
  selectedDate,
  sessionsByDates,
  onAddSession,
  onRemoveSession,
  onSubmit,
  onClose,
  onSelect,
  onPanelChange,
}) {
  const badgeStatus = (session) => {
    if (session.isBooked) return "warning";
    if (session.isModified) return "default";
    return "success";
  };

  const cellRender = (current, info) => {
    if (!sessionsByDates) return;
    if (info.type === "date") {
      const sessions = sessionsByDates[current.format("YYYY-MM-DD")];
      if (!sessions) return;
      const filteredSessions = sessions?.filter(
        (x) => !x.isDeleted //Hide deleted session from calendar
      );
      return filteredSessions.map((x) => (
        <div key={x.startDate}>
          <TimeRange
            status={badgeStatus(x)}
            startDate={x.startDate}
            endDate={x.endDate}
          />
        </div>
      ));
    }
  };
  return (
    <>
      <TimeSlotsDrawer
        open={openDrawer}
        sessions={sessionsByDates[selectedDate.format("YYYY-MM-DD")]}
        selectedDate={selectedDate}
        onClose={onClose}
        onAddSession={onAddSession}
        onRemoveSession={onRemoveSession}
        onSubmit={onSubmit}
      />
      <Calendar
        cellRender={cellRender}
        disabledDate={(currentDate) =>
          currentDate <= new Date().setHours(0, 0, 0, 0)
        }
        onSelect={onSelect}
        onPanelChange={onPanelChange}
      />
    </>
  );
}

export default AvailableTimeSlot;
