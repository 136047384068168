import { formatToDateInUtc } from "../../common/utils/date";
import axiosClient from "../base";

export const getJobTrials = (keyword, page = 1, limit = 10) => {
  return axiosClient.get("jobTrials", {
    params: {
      keyword,
      page,
      limit,
    },
  });
};

export const getBookedJobTrials = (
  keyword,
  page = 1,
  limit = 10,
  fromDate,
  endDate
) => {
  const formattedFromDate = formatToDateInUtc(fromDate);
  const formattedEndDate = formatToDateInUtc(endDate);
  return axiosClient.get("jobTrials/booked", {
    params: {
      keyword,
      page,
      limit,
      fromDate: formattedFromDate,
      endDate: formattedEndDate,
    },
  });
};

export const createJobTrial = (
  company,
  majors,
  englishTitle,
  arabicTitle,
  englishDesc,
  arabicDesc,
  englishDuration,
  arabicDuration,
  englishResponsibilities,
  arabicResponsibilities,
  englishOutcomes,
  arabicOutcomes
) => {
  return axiosClient.post("jobTrials", {
    company,
    majors,
    englishTitle,
    arabicTitle,
    englishDesc,
    arabicDesc,
    englishDuration,
    arabicDuration,
    englishResponsibilities,
    arabicResponsibilities,
    englishOutcomes,
    arabicOutcomes,
  });
};

export const editJobTrial = (
  id,
  company,
  majors,
  englishTitle,
  arabicTitle,
  englishDesc,
  arabicDesc,
  englishDuration,
  arabicDuration,
  englishResponsibilities,
  arabicResponsibilities,
  englishOutcomes,
  arabicOutcomes
) => {
  return axiosClient.put("jobTrials", {
    id,
    company,
    majors,
    englishTitle,
    arabicTitle,
    englishDesc,
    arabicDesc,
    englishDuration,
    arabicDuration,
    englishResponsibilities,
    arabicResponsibilities,
    englishOutcomes,
    arabicOutcomes,
  });
};
