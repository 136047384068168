import { useState } from "react";

import { DatePicker, Flex, notification, Typography, Form } from "antd";

import SearchBar from "../../../../common/components/SearchBar";
import { BOOKED_SESSION_ACTIONS } from "../../../../common/constants/actions";
import { useMajors } from "../../../../contexts/majors";
import { USER_TYPE, useUser } from "../../../../contexts/user";
import { useBookedSessions } from "../../hooks/useBookedSessions";
import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { bookedSessionTableColumns } from "../Table/columns/bookedSession";
import BookedSessionModal from "./BookedSessionModal";
import useStudentDetail from "../../hooks/useStudentDetail";

function BookedSessions() {
  /** STATE */
  const [modal, setModal] = useState({
    isOpen: false,
    action: null,
  });
  const [form] = Form.useForm();
  const { majors } = useMajors();
  const {
    bookedSessions,
    loading,
    dateRange,
    keyword,
    handleOnSearch,
    handleDateRangeChange,
    handleChangeStudentMajors,
    handleEditSessionNotes,
  } = useBookedSessions();
  const { user } = useUser();
  const { studentDetailLoading, getStudentPersonality } = useStudentDetail();
  /** HANDLER */
  const handleOpenModal = async (record, action) => {
    setModal({ isOpen: true, action });
    form.setFieldsValue({
      ...record,
      bookedID: record.id,
      advisorName: record.advisor.englishName,
      studentID: record.student.id,
      studentName: `${record.student.firstName} ${record.student.lastName}`,
      studentMajors: record.student.majors,
      meetingLink: record.googleMeetUrl,
    });
    const studentPersonality = await getStudentPersonality(record.student.id);
    form.setFieldsValue({
      ...studentPersonality,
      traits: studentPersonality.traits.map((x) => x.trait),
    });
  };

  const handleSubmit = async (action, values) => {
    try {
      switch (action) {
        case BOOKED_SESSION_ACTIONS.EDIT_MAJOR:
          const studentID = form.getFieldValue("studentID");
          const sessionID = form.getFieldValue("bookedID");
          const majors = form.getFieldValue("studentMajors");
          await handleChangeStudentMajors(studentID, sessionID, majors);
          break;

        case BOOKED_SESSION_ACTIONS.EDIT_NOTE:
          const bookedId = form.getFieldValue("bookedID");
          const notes = values.notes;
          await handleEditSessionNotes(bookedId, notes);

          break;
        default:
          break;
      }
      handleOnCloseModal();
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  const handleOnCloseModal = () => {
    setModal({ isOpen: false, action: null });
    form.resetFields();
  };

  const handleTabChange = (key) => {
    setModal({ isOpen: true, action: key });
  };
  return (
    <ContentWrapper>
      <Typography.Title className="content__header" level={3}>
        Booked session
      </Typography.Title>
      <Flex gap={24}>
        {user.userType === USER_TYPE.ADMIN && (
          <SearchBar
            value={keyword}
            onSearch={handleOnSearch}
            placeholder={"Search by advisor's name or email"}
          />
        )}
        <DatePicker.RangePicker
          value={[dateRange.fromDate, dateRange.endDate]}
          onChange={handleDateRangeChange}
        />
      </Flex>

      <CustomTable
        loading={loading}
        dataSource={bookedSessions}
        columns={bookedSessionTableColumns(handleOpenModal)}
      />

      <BookedSessionModal
        submitLoading={loading}
        studentDetailLoading={studentDetailLoading}
        majors={majors}
        open={modal.isOpen}
        action={modal.action}
        form={form}
        onClose={handleOnCloseModal}
        onSubmit={handleSubmit}
        onTabChange={handleTabChange}
      />
    </ContentWrapper>
  );
}

export default BookedSessions;
