import { Dropdown as DropdownAntd } from "antd";

import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";
import { USER_TYPE, useUser } from "../../../../contexts/user";
import UserInfo from "../UserInfo";

function Dropdown() {
  /** STATE */

  const navigate = useNavigate();
  const { user, handleLogout, handleChangeAvatar } = useUser();
  const inputFileRef = useRef(null);

  /** HANDLER */
  const handleClickLogoutBtn = () => {
    handleLogout();
    navigate("/");
  };
  const handleOpenSelectFile = () => {
    inputFileRef.current?.click();
  };
  const items = [
    user.userType === USER_TYPE.ADVISOR && {
      key: "1",
      label: (
        <>
          <Button
            className="dropdown_btn"
            type={BUTTON_TYPE.TEXT}
            onClick={handleOpenSelectFile}
          >
            Change avatar
          </Button>
          <input
            style={{ display: "none" }}
            ref={inputFileRef}
            type="file"
            accept="image/*"
            onChange={handleChangeAvatar}
            placeholder="Change avatar"
          />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          className="dropdown_btn"
          type={BUTTON_TYPE.TEXT}
          onClick={handleClickLogoutBtn}
        >
          {" "}
          Log out{" "}
        </Button>
      ),
    },
  ];
  return (
    <>
      <DropdownAntd
        menu={{
          items,
        }}
      >
        <UserInfo
          userType={user.userType}
          profileImage={user.profileImage}
          userName={user.firstName || user.englishName}
        />
      </DropdownAntd>
    </>
  );
}

export default Dropdown;
