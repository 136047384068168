import { useEffect, useState } from "react";

import { debounce } from "lodash";
import { universityService } from "../../../services";
import { notification } from "antd";

export const useUniversities = () => {
  //**** STATE ***//
  const [universities, setUniversities] = useState([]);
  const [universityLoading, setUniversityLoading] = useState(false);
  const [editingUniversity, setEditingUniversity] = useState(null);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);
  const [showManuallyAdded, setShowManuallyAdded] = useState(false);

  const handleManuallyAddedChange = (event) => {
    setShowManuallyAdded(event.target.checked);
  };
  const handleOnSearch = (event) => {
    const value = event.target.value;
    setKeyword(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchUniversities(current);
  };

  const handleSelectUniversity = (university) => {
    setEditingUniversity(university);
  };
  const fetchUniversities = debounce(async (currentPage) => {
    try {
      setUniversityLoading(true);
      const response = await universityService.getUniversities(
        keyword,
        currentPage,
        pagination.pageSize,
        showManuallyAdded
      );
      if (response) {
        setUniversities(response.data.universities);
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setUniversityLoading(false);
    }
  }, 500);

  const handleCreateUniversity = async ({
    university_name,
    campus_names,
    uni_website,
    uni_email,
    regions,
    countries,
    cities,
    rankings_position,
  }) => {
    try {
      setUniversityLoading(true);
      const response = await universityService.createUniversity(
        university_name,
        campus_names,
        uni_website,
        uni_email,
        regions,
        countries,
        cities,
        rankings_position
      );
      if (response) {
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      throw error;
    } finally {
      setUniversityLoading(false);
    }
  };

  const handleEditUniversity = async ({
    id,
    university_name,
    campus_names,
    uni_website,
    uni_email,
    regions,
    countries,
    cities,
    rankings_position,
  }) => {
    try {
      setUniversityLoading(true);
      const response = await universityService.updateUniversity(
        id,
        university_name,
        campus_names,
        uni_website,
        uni_email,
        regions,
        countries,
        cities,
        rankings_position
      );
      if (response) {
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      throw error;
    } finally {
      setUniversityLoading(false);
    }
  };

  const handleDeleteUniversity = async (id) => {
    try {
      const response = await universityService.deleteUniversity(id);
      if (response) {
        setNeedRefresh((prev) => !prev);
        notification["success"]({
          message: "Success!",
          description: "Performing action successfully",
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  useEffect(() => {
    fetchUniversities(pagination.current);

    return () => {
      fetchUniversities.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh, showManuallyAdded]);

  return {
    editingUniversity,
    universities,
    universityLoading,
    pagination,
    keyword,
    showManuallyAdded,
    handleOnSearch,
    handlePagination,
    handleCreateUniversity,
    handleEditUniversity,
    handleDeleteUniversity,
    handleSelectUniversity,
    handleManuallyAddedChange,
  };
};
