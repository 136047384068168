import { DatePicker, Flex, Typography } from "antd";

import SearchBar from "../../../../common/components/SearchBar";
import { useJobApplications } from "../../hooks/useJobApplications";
import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { jobApplicationTableColumns } from "../Table/columns/jobTrial";

function JobApplication() {
  /** STATE */
  const {
    dateRange,
    jobApplications,
    jobApplicationLoading,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleDateRangeChange,
  } = useJobApplications();
  return (
    <ContentWrapper>
      <Flex justify="space-between">
        <Typography.Title className="content__header" level={3}>
          Job Application
        </Typography.Title>
      </Flex>
      <Flex gap={24}>
        <SearchBar
          placeholder="Search by job trial's title name"
          value={keyword}
          onSearch={handleOnSearch}
        />
        <DatePicker.RangePicker
          value={[dateRange.fromDate, dateRange.endDate]}
          onChange={handleDateRangeChange}
        />
      </Flex>
      <CustomTable
        loading={jobApplicationLoading}
        dataSource={jobApplications}
        pagination={pagination}
        columns={jobApplicationTableColumns()}
        onPagination={handlePagination}
      />
    </ContentWrapper>
  );
}

export default JobApplication;
