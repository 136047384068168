import { USER_TYPE } from "../../contexts/user";
import axiosClient from "../base";

export const userServiceFactory = (userType) => {
  if (userType === USER_TYPE.ADMIN)
    return {
      editStudentMajors: editStudentMajorsForAdmin,
    };
  if (userType === USER_TYPE.ADVISOR)
    return {
      editStudentMajors,
    };
};

export const editStudentMajors = (studentID, sessionID, majors) => {
  return axiosClient.put(`students/${studentID}/majors/advisor`, {
    sessionId: sessionID,
    majors,
  });
};

export const editStudentMajorsForAdmin = (studentID, _, majors) => {
  return axiosClient.put(`students/${studentID}/majors/admin`, {
    majors,
  });
};

export const getStudents = (keyword, page = 1, limit = 10) => {
  return axiosClient.get("students", {
    params: {
      keyword,
      page,
      limit,
    },
  });
};

export const deactivateStudent = (studentID) => {
  return axiosClient.put(`students/${studentID}/deactivate`);
};

export const editStudent = ({
  studentID,
  email,
  gender,
  lastName,
  firstName,
  dateOfBirth,
  country,
}) => {
  return axiosClient.put(`students/${studentID}/profile`, {
    email,
    gender,
    lastName,
    firstName,
    dateOfBirth: dateOfBirth.format("YYYY-MM-DD"),
    country,
  });
};

export const getRetakeRequests = (keyword, page = 1, limit = 10) => {
  return axiosClient.get("questionnaireRetakeRequests", {
    params: {
      keyword,
      page,
      limit,
    },
  });
};

export const reviewRetakeRequest = (retakeRequestID, status) => {
  return axiosClient.put(`questionnaireRetakeRequests/${retakeRequestID}`, {
    status,
  });
};
