import "./App.css";
import UserProvider from "./contexts/user";

import { router } from "./routes";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import CountryCodesProvider from "./contexts/countryCodes";

function App() {
  let bluePrimary = "#00B4DE";
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: bluePrimary,
            algorithm: true, // Enable algorithm
          },
          Menu: {
            colorPrimary: bluePrimary,
            algorithm: true,
          },
          Calendar: {
            colorPrimary: bluePrimary,
            algorithm: true,
          },
          Input: {
            colorPrimary: bluePrimary,
            algorithm: true,
          },
          DatePicker: {
            colorPrimary: bluePrimary,
            algorithm: true,
          },
          Checkbox: {
            colorPrimary: bluePrimary,
            algorithm: true,
          },
        },
      }}
    >
      <div className="App">
        <UserProvider>
          <CountryCodesProvider>
            <RouterProvider router={router} />
          </CountryCodesProvider>
        </UserProvider>
      </div>
    </ConfigProvider>
  );
}

export default App;
