import { useState } from "react";

import { Flex, Form, notification, Typography } from "antd";

import { plusIcon } from "../../../../assets/icons";
import { Button, Icon } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";
import SearchBar from "../../../../common/components/SearchBar";
import { JOB_TRIAL_TABLE_ACTIONS } from "../../../../common/constants/actions";
import { checkHasChanges } from "../../../../common/utils/common";
import { useMajors } from "../../../../contexts/majors";
import { useJobTrials } from "../../hooks/useJobTrials";
import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { jobTrialTableColumns } from "../Table/columns";
import JobTrialModal from "./Modal";

function JobTrialManagement() {
  /** STATE */
  const [modal, setModal] = useState({
    isOpen: false,
    action: null,
  });
  const [form] = Form.useForm();
  const [formDisabled, setFormDisabled] = useState(false);
  const {
    keyword,
    jobTrialLoading,
    jobTrials,
    editingJobTrial,
    pagination,
    companies,
    companyLoading,
    handleSelectCompany,
    handleSearchCompany,
    handlePagination,
    handleOnSearch,
    handleSelectJobTrial,
    handleCreateJobTrial,
    handleEditJobTrial,
  } = useJobTrials();

  const { majors } = useMajors();
  const getFieldsToCompare = () => {
    const oldValuesToCompare = { ...editingJobTrial };
    delete oldValuesToCompare.id;
    oldValuesToCompare.company = oldValuesToCompare.company.id;
    oldValuesToCompare.majors = oldValuesToCompare.majors.map((x) => x.id);

    return oldValuesToCompare;
  }; /** HANDLER */
  const mapJobTrialToFormData = (jobTrial) => {
    form.setFieldsValue({
      ...jobTrial,
      company: jobTrial.company.id,
      majors: jobTrial.majors.map((x) => x.id),
    });
  };

  const handleOpenJobTrialModal = (jobTrial, action) => {
    setModal({ isOpen: true, action });
    handleSelectJobTrial(jobTrial);
    switch (action) {
      case JOB_TRIAL_TABLE_ACTIONS.VIEW_INFO:
        mapJobTrialToFormData(jobTrial);
        setFormDisabled(true);
        break;
      case JOB_TRIAL_TABLE_ACTIONS.EDIT_INFO:
        mapJobTrialToFormData(jobTrial);
        handleSelectCompany(jobTrial.company);
        break;

      default:
        break;
    }
  };

  const handleOnSubmit = async (newValue) => {
    try {
      switch (modal.action) {
        case JOB_TRIAL_TABLE_ACTIONS.CREATE_INFO:
          await handleCreateJobTrial(newValue);
          handleCloseModal();
          break;

        case JOB_TRIAL_TABLE_ACTIONS.EDIT_INFO:
          if (!checkHasChanges(getFieldsToCompare(), newValue)) return;
          await handleEditJobTrial({ id: editingJobTrial.id, ...newValue });
          handleCloseModal();
          break;

        default:
          break;
      }
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  const handleCloseModal = () => {
    setModal({ isOpen: false });
    setFormDisabled(false);
    form.resetFields();
  };
  return (
    <ContentWrapper>
      <Flex justify="space-between">
        <Typography.Title className="content__header" level={3}>
          Job Trial
        </Typography.Title>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          icon={<Icon width={16} height={16} src={plusIcon} />}
          onClick={() =>
            handleOpenJobTrialModal(null, JOB_TRIAL_TABLE_ACTIONS.CREATE_INFO)
          }
        >
          Job Trial
        </Button>
      </Flex>

      <SearchBar
        placeholder="Search by title"
        value={keyword}
        onSearch={handleOnSearch}
      />
      <CustomTable
        loading={jobTrialLoading}
        dataSource={jobTrials}
        pagination={pagination}
        columns={jobTrialTableColumns(handleOpenJobTrialModal)}
        onPagination={handlePagination}
      />
      <JobTrialModal
        loading={jobTrialLoading}
        formDisabled={formDisabled}
        majors={majors}
        companies={companies}
        companyLoading={companyLoading}
        action={modal.action}
        open={modal.isOpen}
        form={form}
        onCancel={handleCloseModal}
        onFinish={handleOnSubmit}
        onSearchCompany={handleSearchCompany}
      />
    </ContentWrapper>
  );
}

export default JobTrialManagement;
