import { Flex, Popconfirm, Typography } from "antd";

import { editIcon, rejectIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";
import { PROGRAM_TABLE_ACTIONS } from "../../../../../../common/constants/actions";

export const programTableColumns = (onOpenModal, onDelete) => [
  {
    title: "Major",
    key: "major",
    dataIndex: "major",
  },
  {
    title: "Program",
    dataIndex: "program",
    key: "program",
  },
  {
    title: "University Name",
    dataIndex: "university_name",
    key: "universityName",
  },
  {
    title: "Attributes",
    dataIndex: "programAttributes",
    key: "program_attributes",
    render: (_, { program_attributes }) => {
      if (!program_attributes) return;
      return Object.keys(program_attributes).map((x) => (
        <Flex vertical gap={10}>
          <div>
            <Typography.Text strong>{x}: </Typography.Text>
            <Typography.Text>{program_attributes[x]}</Typography.Text>
          </div>
        </Flex>
      ));
    },
  },
  {
    title: "Study Destination",
    dataIndex: "study_destination",
    key: "studyDestination",
  },
  {
    title: "Apply Study Level",
    dataIndex: "apply_study_level",
    key: "applyStudyLevel",
  },
  {
    title: "Additional Info",
    dataIndex: "additional_info",
    key: "additionalInfo",
    render: (_, { additional_info }) => {
      if (!additional_info) return;
      return Object.keys(additional_info).map((x) => (
        <Flex vertical gap={10}>
          <div>
            <Typography.Text strong>{x}: </Typography.Text>
            <Typography.Text>{additional_info[x]}</Typography.Text>
          </div>
        </Flex>
      ));
    },
  },
  {
    title: "Admission Requirements",
    dataIndex: "admission_requirements",
    key: "admissionRequirements",
    render: (_, { admission_requirements }) => {
      if (!admission_requirements) return;

      return Object.keys(admission_requirements).map((x) => (
        <Flex vertical gap={10}>
          <div>
            <Typography.Text strong>{x}: </Typography.Text>
            <Typography.Text>{admission_requirements[x]}</Typography.Text>
          </div>
        </Flex>
      ));
    },
  },
  {
    title: "Action",
    key: "action",
    render: (_, program) => {
      return (
        <Flex align="center" gap={10}>
          <Icon
            className="logo__action"
            preview={false}
            src={editIcon}
            onClick={() =>
              onOpenModal(program, PROGRAM_TABLE_ACTIONS.EDIT_PROGRAM)
            }
          />
          <Popconfirm
            title="Delete this record?"
            onConfirm={() => onDelete(program.id)}
          >
            <Icon className="logo__action" preview={false} src={rejectIcon} />
          </Popconfirm>
        </Flex>
      );
    },
  },
];
