import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000/api/";

const axiosClient = axios.create({
  headers: {
    "content-type": "application/json",
  },
  baseURL: apiUrl,
});
axiosClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
