import axiosClient from "../base";

export const getUniversities = (
  keyword,
  page = 1,
  limit = 10,
  showManuallyAdded = false
) => {
  return axiosClient.get("universities/admin", {
    params: {
      keyword,
      page,
      limit,
      manuallyAdded: showManuallyAdded,
    },
  });
};

export const updateUniversity = (
  id,
  university_name,
  campus_names,
  uni_website,
  uni_email,
  regions,
  countries,
  cities,
  rankings_position
) => {
  return axiosClient.put("universities", {
    id,
    university_name,
    campus_names,
    uni_website,
    uni_email,
    regions,
    countries,
    cities,
    rankings_position,
  });
};

export const deleteUniversity = (id) => {
  return axiosClient.delete("universities", {
    data: id,
  });
};

export const createUniversity = (
  university_name,
  campus_names,
  uni_website,
  uni_email,
  regions,
  countries,
  cities,
  rankings_position
) => {
  return axiosClient.post("universities", {
    university_name,
    campus_names,
    uni_website,
    uni_email,
    regions,
    countries,
    cities,
    rankings_position,
  });
};
