import { Flex, Form, Spin } from "antd";
import { Navigate, useNavigate } from "react-router-dom";

import { Image } from "antd";
import { useState } from "react";
import { logo } from "../../assets/icons";
import { useUser } from "../../contexts/user";
import AuthForm from "./components/Form";
import "./index.css";
export const AUTH_STATES = {
  SEND_OTP: "Send otp",
  VERIFY_OTP: "Verify otp",
};
function Login() {
  const [form] = Form.useForm();
  const [authState, setAuthState] = useState(AUTH_STATES.SEND_OTP);
  const { handleSendOtp, handleVerifyOtp, isAuthenticated, isLoading } =
    useUser();
  const navigate = useNavigate();
  const handleOnFinish = async (values) => {
    try {
      if (authState === AUTH_STATES.SEND_OTP) {
        await handleSendOtp(values.countryCode, values.whatsAppNumber);
        setAuthState(AUTH_STATES.VERIFY_OTP);
        form.setFieldsValue({
          whatsAppNumber: values.whatsAppNumber,
          countryCode: values.countryCode,
        });
      }

      if (authState === AUTH_STATES.VERIFY_OTP) {
        await handleVerifyOtp(
          values.otp,
          form.getFieldValue("countryCode"),
          form.getFieldValue("whatsAppNumber")
        );
        navigate("/dashboard");
      }
    } catch (error) {}
  };

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return isLoading ? (
    <Spin className="spinner" />
  ) : (
    <Flex
      className="login__container"
      justify="center"
      align="center"
      vertical
      gap={20}
    >
      <Image width={150} preview={false} src={logo} />
      <AuthForm authState={authState} form={form} onFinish={handleOnFinish} />
    </Flex>
  );
}

export default Login;
