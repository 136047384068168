import { useState } from "react";
import { personalityService } from "../../../services";
import { notification } from "antd";

function useStudentDetail() {
  //**** STATE ***//
  const [studentDetailByStudentID, setStudentDetailByStudentID] = useState({});
  const [studentDetailLoading, setStudentDetailLoading] = useState(true);

  //**** HANDLER ***//

  const getStudentPersonality = async (studentID) => {
    try {
      if (studentDetailByStudentID[studentID]) {
        return studentDetailByStudentID[studentID];
      }

      setStudentDetailLoading(true);
      const res = await personalityService.getPersonality(studentID);
      setStudentDetailByStudentID({
        ...studentDetailByStudentID,
        [studentID]: res.data,
      });
      return res.data;
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    } finally {
      setStudentDetailLoading(false);
    }
  };

  return {
    studentDetailLoading,
    getStudentPersonality,
  };
}

export default useStudentDetail;
