import { useEffect, useState } from "react";

import moment from "moment";
import { formatToShortDate } from "../../../common/utils/date";
import { useUser } from "../../../contexts/user";
import { sessionService } from "../../../services";
import { sessionsByDatesMapper } from "../common/mapper";
import { notification } from "antd";

export const useSessions = () => {
  /**** STATE ***/
  const { user } = useUser();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [needRefresh, setNeedRefresh] = useState(false);
  const [selectedYearMonth, setSelectedYearMonth] = useState({
    year: moment().year(),
    month: moment().month(),
  });

  const formattedSelectedDate = formatToShortDate(selectedDate);

  const [sessionsByDates, setSessionsByDates] = useState({});

  const fetchSessions = async () => {
    try {
      const response = await sessionService.getSessions(selectedYearMonth);
      if (response) {
        setSessionsByDates(sessionsByDatesMapper(response.data));
      }
    } catch (error) {}
  };

  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  const handleYearMonthChange = (value) => {
    const month = value.month();
    const year = value.year();
    setSelectedYearMonth({
      month,
      year,
    });
  };
  const isOverlapping = (selectedRange, timeRange) => {
    const [selectedStartDate, selectedEndDate] = selectedRange;
    const { startDate, endDate } = timeRange;
    return (
      (selectedStartDate.isAfter(startDate) &&
        selectedStartDate.isBefore(endDate)) ||
      (selectedEndDate.isAfter(startDate) &&
        selectedEndDate.isBefore(endDate)) ||
      selectedStartDate.isSame(startDate)
    );
  };
  const handleAddSession = ([selectedStartDate, selectedEndDate]) => {
    if (!selectedStartDate && !selectedEndDate) return;

    //convert to local ISO format
    const formattedStartDate = selectedStartDate.format();
    const formattedEndDate = selectedEndDate.format();
    setSessionsByDates((prev) => {
      const newSessions = { ...prev };
      if (newSessions.hasOwnProperty(formattedSelectedDate)) {
        const isOverlap = newSessions[formattedSelectedDate].some((x) =>
          isOverlapping([selectedStartDate, selectedEndDate], x)
        );

        if (isOverlap) {
          notification["error"]({
            message: "Error!",
            description: "Overlapping time slot. Please add other time slot",
          });
          return newSessions;
        }
        newSessions[formattedSelectedDate].unshift({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          isModified: true,
          advisorId: user.id,
        });
      } else {
        newSessions[formattedSelectedDate] = [
          {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            isModified: true,
            advisorId: user.id,
          },
        ];
      }

      newSessions[formattedSelectedDate].sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      ); //Ascending ordering by startDate
      return newSessions;
    });
  };

  const handleRemoveSession = (session) => {
    setSessionsByDates((prev) => {
      const newSessionsByDate = { ...prev };
      if (session.id) {
        newSessionsByDate[formattedSelectedDate] = newSessionsByDate[
          formattedSelectedDate
        ].map((x) => {
          return x.id === session.id
            ? {
                ...x,
                isDeleted: true,
                isModified: true,
                advisorId: x.advisor.id,
              }
            : x;
        });
      } else {
        newSessionsByDate[formattedSelectedDate] = newSessionsByDate[
          formattedSelectedDate
        ].filter((x) => x.startDate !== session.startDate);
      }
      return newSessionsByDate;
    });
  };
  const handleSaveNewSessions = async () => {
    const newSessions = sessionsByDates[formattedSelectedDate].filter(
      (x) => x.isModified
    );
    const response = await sessionService.addSessionsForAdvisor(newSessions);
    if (response) {
      setNeedRefresh((prev) => !prev);
    }
  };

  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh, selectedYearMonth]);

  return {
    sessionsByDates,
    selectedDate,
    handleDateChange,
    handleYearMonthChange,
    handleAddSession,
    handleSaveNewSessions,
    handleRemoveSession,
  };
};
