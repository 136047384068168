import { Badge, Typography } from "antd";

import moment from "moment";
import "./index.css";

const { Text } = Typography;
function TimeRange({ startDate, endDate, status }) {
  return (
    <Badge
      className="time__range"
      status={status}
      text={
        <Text className="time__range__text">{`${moment(startDate).format(
          "HH:mm"
        )} - ${moment(endDate).format("HH:mm A")}`}</Text>
      }
    />
  );
}

export default TimeRange;
