import { DatePicker as DatePickerAntd } from "antd";
function DatePicker({ value, onCalendarChange, ...props }) {
  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  return (
    <DatePickerAntd
      {...props}
      disabledDate={disabledDate}
      onCalendarChange={onCalendarChange}
      value={value}
    />
  );
}

export default DatePicker;
