import { useState } from "react";

import { useSessions } from "../../hooks/useSessions";
import ContentWrapper from "../ContentWrapper";

import { notification } from "antd";
import AvailableTimeSlot from "./AvailableTimeSlot";
import "./index.css";

function TimeSlotManagement() {
  const {
    sessionsByDates,
    selectedDate,
    handleDateChange,
    handleYearMonthChange,
    handleAddSession,
    handleSaveNewSessions,
    handleRemoveSession,
  } = useSessions();
  const [openDrawer, setOpenDrawer] = useState(false);

  /** HANDLER */
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCalendarValueChange = (value) => {
    handleDateChange(value);
    handleOpenDrawer();
  };

  const handleSubmit = async () => {
    try {
      await handleSaveNewSessions();
      handleCloseDrawer();
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };

  const handlePanelChange = (date, mode) => {
    if (mode === "month") {
      handleYearMonthChange(date);
    }
  };
  return (
    <>
      <ContentWrapper>
        <AvailableTimeSlot
          sessionsByDates={sessionsByDates}
          openDrawer={openDrawer}
          selectedDate={selectedDate}
          onClose={handleCloseDrawer}
          onAddSession={handleAddSession}
          onRemoveSession={handleRemoveSession}
          onSubmit={handleSubmit}
          onSelect={handleCalendarValueChange}
          onPanelChange={handlePanelChange}
        />
      </ContentWrapper>
    </>
  );
}

export default TimeSlotManagement;
