import moment from "moment";

export function formatTableDate(date) {
  const padZero = (num) => (num < 10 ? "0" + num : num);

  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1); // Months are zero-based
  const year = date.getFullYear();

  return `${hours}:${minutes}, ${day}/${month}/${year}`;
}
export function formatToBookedSessionDate(startDateISO) {
  // Parse the start date from ISO string (this automatically adjusts to the local timezone)
  const localStartDate = new Date(startDateISO);

  // Create the end date by adding 50 minutes
  const localEndDate = new Date(localStartDate.getTime() + 50 * 60 * 1000);

  // Create a date formatter for the day, month, and year part
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  // Get the formatted date part (same for both start and end)
  const formattedDate = dateFormatter.format(localStartDate);

  // Format the time to 12-hour format with AM/PM
  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle 0 as 12
    return `${hours}:${minutes} ${ampm}`;
  };

  const startTime = formatTime(localStartDate);
  const endTime = formatTime(localEndDate);

  // Return the final formatted string
  return `${formattedDate}  .  ${startTime} - ${endTime} (${getFullTimezoneOffset()})`;
}

export function formatToSessionUtc(startDate) {
  return moment(startDate).utc().format();
}

export function formatToDateInUtc(date) {
  return date.toISOString().split("T")[0];
}

export function getTimeRange(year, month) {
  //As the calendar shows a few days of previous or next month => Fix by using date range [current month - 1 , current month + 1]
  const fromDate = moment()
    .year(year)
    .month(month - 1)
    .startOf("month")
    .format("YYYY-MM-DD HH:mm:ss.SSSSSS");
  const endDate = moment()
    .year(year)
    .month(month + 1)
    .endOf("month")
    .format("YYYY-MM-DD HH:mm:ss.SSSSSS");
  return [fromDate, endDate];
}

export function formatToShortDate(date) {
  return date.format("YYYY-MM-DD");
}

export function getFullTimezoneOffset() {
  const offset = new Date().getTimezoneOffset(); // returns the offset in minutes
  const absoluteOffset = Math.abs(offset);

  const hours = Math.floor(absoluteOffset / 60);
  const minutes = absoluteOffset % 60;

  const sign = offset <= 0 ? "+" : "-"; // positive offset means UTC ahead, so we use '+'

  return `UTC ${sign}${hours}${
    minutes > 0 ? `:${minutes.toString().padStart(2, "0")}` : ""
  }`;
}
export function getTimeZoneName() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}
