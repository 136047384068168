import { Image } from "antd";

function Icon({
  clickable,
  className,
  height = 30,
  width = 30,
  src,
  onClick,
  ...props
}) {
  return (
    <Image
      {...props}
      className={`${className} ${clickable && "logo__action "}`}
      height={height}
      width={width}
      preview={false}
      src={src}
      onClick={onClick}
    />
  );
}

export default Icon;
