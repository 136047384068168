import { Spin, Table } from "antd";

import "./index.css";

function CustomTable({
  loading,
  dataSource,
  pagination,
  columns,
  onPagination,
  ...props
}) {
  return loading ? (
    <Spin className="spinner" />
  ) : (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      onChange={onPagination}
      rowKey={(value) => {
        return value.id;
      }}
      {...props}
    />
  );
}

export default CustomTable;
