export const STUDENT_TABLE_ACTIONS = {
  DEACTIVATE_STUDENT: "Deactivate Student",
  EDIT_INFO: "Edit Info",
  UPDATE_MAJOR: "Update Major",
};
export const ADVISOR_TABLE_ACTIONS = {
  EDIT_INFO: "Edit Advisor",
  CREATE_INFO: "Create Advisor",
  TIME_SLOT: "Time Slot",
};

export const COMPANY_TABLE_ACTIONS = {
  EDIT_INFO: "Edit Company",
  CREATE_INFO: "Create Company",
};

export const JOB_TRIAL_TABLE_ACTIONS = {
  VIEW_INFO: "View Job Trial",
  EDIT_INFO: "Edit Job Trial",
  CREATE_INFO: "Create Job Trial",
};

export const BOOKED_SESSION_ACTIONS = {
  EDIT_MAJOR: "Edit Major",
  EDIT_NOTE: "Edit Note",
  VIEW_INFO: "View Info",
};

export const PROGRAM_TABLE_ACTIONS = {
  EDIT_PROGRAM: "Edit Program",
  CREATE_PROGRAM: "Create Program",
  DELETE_PROGRAM: "Delete Program",
};

export const UNIVERSITY_TABLE_ACTIONS = {
  CREATE_UNIVERSITY: "Create University",
  EDIT_UNIVERSITY: "Edit University",
  DELETE_UNIVERSITY: "Delete University",
};
