import { useEffect, useState } from "react";

import { debounce } from "lodash";
import { companyService, jobTrialService } from "../../../services";
import { companiesTableDataSourceMapper } from "../common/mapper";

export const useJobTrials = () => {
  //**** STATE ***//
  const [jobTrials, setJobTrials] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [editingJobTrial, setEditingJobTrial] = useState(null);
  const [jobTrialLoading, setJobTrialLoading] = useState(null);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);

  const handleOnSearch = (event) => {
    setKeyword(event.target.value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchJobTrials(current);
  };

  const handleSelectJobTrial = (JobTrial) => {
    setEditingJobTrial(JobTrial);
  };
  const fetchJobTrials = debounce(async (currentPage) => {
    try {
      setJobTrialLoading(true);
      const response = await jobTrialService.getJobTrials(
        keyword,
        currentPage,
        pagination.pageSize
      );
      if (response) {
        setJobTrials(response.data.jobTrials);
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setJobTrialLoading(false);
    }
  }, 500);
  const handleCreateJobTrial = async ({
    company,
    majors,
    englishTitle,
    arabicTitle,
    englishDesc,
    arabicDesc,
    englishDuration,
    arabicDuration,
    englishResponsibilities,
    arabicResponsibilities,
    englishOutcomes,
    arabicOutcomes,
  }) => {
    try {
      setJobTrialLoading(true);
      const response = await jobTrialService.createJobTrial(
        company,
        majors,
        englishTitle,
        arabicTitle,
        englishDesc,
        arabicDesc,
        englishDuration,
        arabicDuration,
        englishResponsibilities,
        arabicResponsibilities,
        englishOutcomes,
        arabicOutcomes
      );
      if (response) {
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      throw error;
    } finally {
      setJobTrialLoading(false);
    }
  };
  const handleEditJobTrial = async ({
    id,
    company,
    majors,
    englishTitle,
    arabicTitle,
    englishDesc,
    arabicDesc,
    englishDuration,
    arabicDuration,
    englishResponsibilities,
    arabicResponsibilities,
    englishOutcomes,
    arabicOutcomes,
  }) => {
    try {
      setJobTrialLoading(true);
      const response = await jobTrialService.editJobTrial(
        id,
        company,
        majors,
        englishTitle,
        arabicTitle,
        englishDesc,
        arabicDesc,
        englishDuration,
        arabicDuration,
        englishResponsibilities,
        arabicResponsibilities,
        englishOutcomes,
        arabicOutcomes
      );
      if (response) {
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      throw error;
    } finally {
      setJobTrialLoading(false);
    }
  };
  const handleSelectCompany = (company) => {
    setCompanies(companiesTableDataSourceMapper([company]));
  };
  const handleSearchCompany = debounce((keyword) => {
    if (keyword.trim().length < 3) return;
    fetchCompany(keyword);
  }, 1000);

  const fetchCompany = async (keyword) => {
    try {
      setCompanyLoading(true);
      const response = await companyService.getCompanies(keyword, null, null); //get all
      if (response) {
        setCompanies(companiesTableDataSourceMapper(response.data.companies));
      }
    } catch (error) {
    } finally {
      setCompanyLoading(false);
    }
  };

  useEffect(() => {
    fetchJobTrials(pagination.current);

    return () => {
      fetchJobTrials.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh]);

  return {
    companies,
    companyLoading,
    editingJobTrial,
    jobTrialLoading,
    jobTrials,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleCreateJobTrial,
    handleEditJobTrial,
    handleSelectJobTrial,
    handleSearchCompany,
    handleSelectCompany,
  };
};
