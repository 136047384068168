import axiosClient from "../base";

export const createCompanyFormData = (values) => {
  const {
    id,
    englishName,
    arabicName,
    englishAddress,
    arabicAddress,
    email,
    website,
  } = values;
  const countryCode = values.phoneNumber.split(" ")[0];
  const phone = values.phoneNumber.split(" ")[1];
  const profileImage = values.profileImage[0].originFileObj; // Get the actual file object

  const formData = new FormData();
  formData.append("id", id);
  formData.append("englishName", englishName);
  formData.append("arabicName", arabicName);
  formData.append("englishAddress", englishAddress);
  formData.append("arabicAddress", arabicAddress);
  formData.append("email", email);
  formData.append("website", website);
  formData.append("countryCode", countryCode);
  formData.append("phone", phone);
  formData.append("profileImage", profileImage);

  return formData;
};

export const getCompanies = (keyword, page = 1, limit = 10) => {
  return axiosClient.get("companies", {
    params: {
      keyword,
      page,
      limit,
    },
  });
};

export const createCompany = (values) => {
  return axiosClient.post("companies", createCompanyFormData(values), {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editCompany = (values) => {
  return axiosClient.put("companies", createCompanyFormData(values), {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
