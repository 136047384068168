import { useEffect, useState } from "react";

import { debounce } from "lodash";
import { jobApplicationsTableDataSourceMapper } from "../common/mapper";
import { jobTrialService } from "../../../services";

import dayjs from "dayjs";

export const useJobApplications = () => {
  //**** STATE ***//
  const [jobApplications, setJobApplications] = useState([]);
  const [jobApplicationLoading, setJobApplicationLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);
  const [dateRange, setDateRange] = useState({
    fromDate: dayjs().startOf("day"),
    endDate: dayjs().startOf("day").add(7, "day"),
  });

  const handleDateRangeChange = (value) => {
    setDateRange({ fromDate: value[0], endDate: value[1] });
  };
  const handleOnSearch = (event) => {
    const value = event.target.value;
    setKeyword(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchJobApplications(current);
  };

  const fetchJobApplications = debounce(async (currentPage) => {
    try {
      setJobApplicationLoading(true);
      const response = await jobTrialService.getBookedJobTrials(
        keyword,
        currentPage,
        pagination.pageSize,
        dateRange.fromDate,
        dateRange.endDate
      );

      if (response) {
        setJobApplications(
          jobApplicationsTableDataSourceMapper(response.data.jobTrials)
        );
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setJobApplicationLoading(false);
    }
  }, 500);

  useEffect(() => {
    fetchJobApplications(pagination.current);

    return () => {
      fetchJobApplications.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, dateRange]);

  return {
    dateRange,
    jobApplications,
    jobApplicationLoading,
    pagination,
    keyword,
    handleDateRangeChange,
    handleOnSearch,
    handlePagination,
  };
};
