import { Button, Form, Input, Select, Typography } from "antd";
import { AUTH_STATES } from "../..";
import { Icon } from "../../../../common/components";
import { useCountryCodes } from "../../../../contexts/countryCodes";

import "./index.css";
function AuthForm({ authState, form, onFinish }) {
  const { countryCodes } = useCountryCodes();
  const renderAuthForm = () => {
    if (authState === AUTH_STATES.SEND_OTP)
      return (
        <>
          <Typography.Title className="send__otp__title" level={5}>
            Please select a country and enter your WhatsApp phone number
          </Typography.Title>
          <Form.Item
            name="countryCode"
            rules={[
              {
                required: true,
                message: "Please select country code!",
              },
            ]}
          >
            <Select showSearch optionFilterProp="children">
              {countryCodes?.map((x) => (
                <Select.Option key={x.code} value={x.code}>
                  <Icon width={20} loading="eager" src={`/flags/${x.image}`} />{" "}
                  {x.code} ({x.country})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="whatsAppNumber"
            rules={[
              {
                required: true,
                message: "Please input whatsAppNumber!",
              },
            ]}
          >
            <Input
              type="number"
              controls={false}
              placeholder="Enter whatsAppNumber"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </>
      );
    if (authState === AUTH_STATES.VERIFY_OTP)
      return (
        <>
          <Typography.Title level={5} className="send__otp__title">
            Please input OTP code sent to your WhatsApp
          </Typography.Title>

          <Form.Item
            name="otp"
            rules={[
              {
                required: true,
                message: "Please input your otp code!",
              },
              { len: 6, message: "OTP must be 6 digits!" },
            ]}
          >
            <Input.OTP
              style={{ width: "100%" }}
              size="large"
              formatter={(str) => str.toUpperCase()}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </>
      );
  };
  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      {renderAuthForm()}
    </Form>
  );
}

export default AuthForm;
