import { useState, createContext, useContext, useEffect } from "react";

import { countryService } from "../../services";

const CountryContext = createContext();

function CountryCodesProvider({ children }) {
  const [countryCodes, setCountryCodes] = useState(null);

  const getCountryCodes = async () => {
    try {
      const cachedCountryCodes = sessionStorage.getItem("countryCodes");
      if (cachedCountryCodes) {
        setCountryCodes(JSON.parse(cachedCountryCodes));
        return;
      }
      const response = await countryService.getCountryCodes();
      if (response) {
        sessionStorage.setItem(
          "countryCodes",
          JSON.stringify(response.data.countryCallingCodes)
        );
        setCountryCodes(response.data.countryCallingCodes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCountryCodes();
  }, []);
  return (
    <CountryContext.Provider value={{ countryCodes }}>
      {children}
    </CountryContext.Provider>
  );
}
export default CountryCodesProvider;

export const useCountryCodes = () => {
  return useContext(CountryContext);
};
