import { TimePicker as TimePickerAntd } from "antd";
import dayjs from "dayjs";

function TimePicker({
  selectedDateISO,
  value: [startTime, endTime],
  onCalendarChange,
  ...props
}) {
  const now = dayjs();

  const isSameDayAsNow = dayjs(selectedDateISO)?.isSame(now, "day");
  const disabledRangeHours = () => {
    if (isSameDayAsNow) {
      const hours = [];
      for (let i = 0; i < now.hour(); i++) {
        hours.push(i);
      }
      return hours;
    }
    return [];
  };

  const disabledRangeMinutes = (selectedHour) => {
    if (isSameDayAsNow && selectedHour === now.hour()) {
      const minutes = [];
      for (let i = 0; i < now.minute(); i++) {
        minutes.push(i);
      }
      return minutes;
    }
    return [];
  };

  const disabledRangeSeconds = (selectedHour, selectedMinute) => {
    if (
      isSameDayAsNow &&
      selectedHour === now.hour() &&
      selectedMinute === now.minute()
    ) {
      const seconds = [];
      for (let i = 0; i < now.second(); i++) {
        seconds.push(i);
      }
      return seconds;
    }
    return [];
  };
  return (
    <TimePickerAntd.RangePicker
      {...props}
      value={[startTime, endTime]}
      onCalendarChange={onCalendarChange}
      format="HH:mm"
      showTime={{
        format: "HH:mm",
      }}
      disabledHours={() => disabledRangeHours()}
      disabledMinutes={(selectedHour) => disabledRangeMinutes(selectedHour)}
      disabledSeconds={(selectedHour, selectedMinute) =>
        disabledRangeSeconds(selectedHour, selectedMinute)
      }
    />
  );
}

export default TimePicker;
