import {
  userIcon,
  companyIcon,
  jobTrialIcon,
  universityIcon,
  studyProgramIcon,
  crawlingJobIcon,
  retakeRequestsIcon,
  productIcon,
  appointmentIcon,
} from "../../../../assets/icons";
import { Icon } from "../../../../common/components";
import { USER_TYPE } from "../../../../contexts/user";

import "./index.css";
export const MENU_KEY = {
  USER: "User",
  STUDENTS: "Students",
  ADVISORS: "Advisors",
  COMPANIES: "Companies",
  JOB_TRIALS: "Job Trials",
  JOB_APPLICATION: "Job Application",
  RETAKE: "Retake Requests",
  APPOINTMENTS: "Appointment",
  TIME_SLOTS: "Available Time Slots",
  BOOKED_SESSIONS: "Booked Sessions",
  PROGRAMS: "Programs",
  UNIVERSITIES: "Universities",
  CRAWLING_JOBS: "Crawling Jobs",
  PRODUCTS: "Products",
};

export const menuItems = (userType) =>
  userType === USER_TYPE.ADMIN ? adminMenuItems : advisorMenuItems;

const adminMenuItems = [
  {
    key: MENU_KEY.USER,
    label: MENU_KEY.USER,
    icon: <Icon src={userIcon} />,
    children: [
      {
        key: MENU_KEY.STUDENTS,
        label: MENU_KEY.STUDENTS,
      },
      {
        key: MENU_KEY.ADVISORS,
        label: MENU_KEY.ADVISORS,
      },
    ],
  },
  {
    key: MENU_KEY.COMPANIES,
    label: MENU_KEY.COMPANIES,
    icon: <Icon src={companyIcon} />,
  },
  {
    key: MENU_KEY.JOB_TRIALS,
    label: MENU_KEY.JOB_TRIALS,
    icon: <Icon src={jobTrialIcon} />,
  },
  {
    key: MENU_KEY.RETAKE,
    label: MENU_KEY.RETAKE,
    icon: <Icon src={retakeRequestsIcon} />,
  },
  {
    key: MENU_KEY.APPOINTMENTS,
    label: MENU_KEY.APPOINTMENTS,
    icon: <Icon src={appointmentIcon} />,
    children: [
      {
        key: MENU_KEY.BOOKED_SESSIONS,
        label: MENU_KEY.BOOKED_SESSIONS,
      },
      {
        key: MENU_KEY.JOB_APPLICATION,
        label: MENU_KEY.JOB_APPLICATION,
      },
    ],
  },
  {
    key: MENU_KEY.UNIVERSITIES,
    label: MENU_KEY.UNIVERSITIES,
    icon: <Icon src={universityIcon} />,
  },
  {
    key: MENU_KEY.PROGRAMS,
    label: MENU_KEY.PROGRAMS,
    icon: <Icon src={studyProgramIcon} />,
  },
  {
    key: MENU_KEY.CRAWLING_JOBS,
    label: MENU_KEY.CRAWLING_JOBS,
    icon: <Icon src={crawlingJobIcon} />,
  },
  {
    key: MENU_KEY.PRODUCTS,
    label: MENU_KEY.PRODUCTS,
    icon: <Icon src={productIcon} />,
  },
];

const advisorMenuItems = [
  {
    key: MENU_KEY.APPOINTMENTS,
    label: MENU_KEY.APPOINTMENTS,
    icon: <Icon src={appointmentIcon} />,
    children: [
      {
        key: MENU_KEY.TIME_SLOTS,
        label: MENU_KEY.TIME_SLOTS,
      },
      {
        key: MENU_KEY.BOOKED_SESSIONS,
        label: MENU_KEY.BOOKED_SESSIONS,
      },
    ],
  },
];
