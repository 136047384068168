import { Flex, Typography } from "antd";

import { getTimeZoneName, getFullTimezoneOffset } from "../../utils/date";
import Button, { BUTTON_TYPE } from "../Button";
import Icon from "../Icon";
import { clockIcon } from "../../../assets/icons";

function SessionTimeInfo() {
  return (
    <Flex align="center" justify="space-between">
      <Typography.Text strong>
        {getTimeZoneName()} ({getFullTimezoneOffset()})
      </Typography.Text>
      <Button
        className="drawer__button__default"
        disabled
        type={BUTTON_TYPE.TEXT}
        icon={<Icon width={16} height={16} src={clockIcon} />}
      >
        50-min meeting
      </Button>
    </Flex>
  );
}

export default SessionTimeInfo;
