import { advisorTableColumns } from "./advisor";
import { studentTableColumns } from "./student";
import { retakeRequestTableColumns } from "./retakeRequest";
import { companyTableColumns } from "./company";
import { jobTrialTableColumns } from "./jobTrial";
import { crawlingJobTableColumns } from "./crawlingJob";

export const NULL_VALUE = "-";
export {
  advisorTableColumns,
  studentTableColumns,
  retakeRequestTableColumns,
  companyTableColumns,
  jobTrialTableColumns,
  crawlingJobTableColumns,
};
