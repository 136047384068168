import { useState } from "react";

import {
  Dropdown,
  Flex,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
  Typography,
} from "antd";

import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { crawlingJobTableColumns } from "../Table/columns";
import { useCrawlingJobs } from "../../hooks/useCrawlingJobs";
import { Button } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";

import "./index.css";
function CrawlingManagement() {
  const {
    crawlingJobs,
    crawlingJobsLoading,
    handleTriggerApiCrawling,
    handleTriggerHtmlCrawling,
    handleTriggerOpenAICrawling,
    handleRefresh,
  } = useCrawlingJobs();

  const [universities, setUniversities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleOk = async () => {
    await handleTriggerOpenAICrawling(universities);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const items = [
    {
      key: "1",
      label: (
        <Tooltip
          placement="right"
          title='When you click the "Scrape Data from QS API" button, the system will collect and save university and program information from topuniversities.com into two files, which will be used to update the database and override the current data: 
        *Programs List: Contains the available programs offered by QS-ranked universities.
        *Universities List: Contains details of the universities. 
         This process is part of the initial data collection and will take approximately 10-15 hours to complete.
         Important Note:
         Program-specific details (e.g., admission requirements, program overview) are not included in this step.
         To gather this information, you will need to use the "Scrape Program Details" feature later.'
        >
          <Popconfirm
            title="Trigger the job"
            description="This action will take up to 7 days to finish. Do you want to continue?"
            okText="Yes"
            cancelText="No"
            onConfirm={handleTriggerApiCrawling}
          >
            <p>QS-api</p>
          </Popconfirm>
        </Tooltip>
      ),
    },
    {
      key: "2",
      label: (
        <Tooltip
          placement="right"
          title='When you click the "Scrape Program Details" button, the system will collect detailed information about programs from university websites available on topuniversities.com. This includes:*Program Overview *Admission Requirements *Other relevant program details Key Information: *The system will use the latest Programs List file to identify the programs. *Once the process is complete, a new file will be created with the detailed program information. This file will be used to update the database. Process Duration: This process is detailed and requires a significant amount of time, taking approximately 5-7 days to complete. Please ensure the process is started when sufficient time is available. Important Note: You do not need to monitor the system continuously, but please allow the process to finish before attempting other related tasks.'
        >
          <Popconfirm
            title="Trigger the job"
            description="This action will take up to 7 days to finish. Do you want to continue?"
            okText="Yes"
            cancelText="No"
            onConfirm={handleTriggerHtmlCrawling}
          >
            <p>Program details-html</p>
          </Popconfirm>
        </Tooltip>
      ),
    },
    // {
    //   key: "3",
    //   label: <p onClick={showModal}>Programs for universities-OpenAI</p>,
    // },
  ];

  return (
    <ContentWrapper>
      <Flex justify="space-between">
        <Typography.Title className="content__header" level={3}>
          Crawling job
        </Typography.Title>
      </Flex>

      <Flex justify="space-between">
        <Dropdown menu={{ items }} placement="bottomLeft" arrow>
          <Button>Trigger Crawling Job</Button>
        </Dropdown>
        <Button onClick={handleRefresh} type={BUTTON_TYPE.PRIMARY}>
          Refresh
        </Button>
      </Flex>

      <CustomTable
        loading={crawlingJobsLoading}
        dataSource={crawlingJobs}
        columns={crawlingJobTableColumns()}
      />
      <Modal
        title="Universities"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          className="universities__select"
          value={universities}
          onChange={(value) => {
            setUniversities(value);
          }}
          mode="tags"
          placeholder="Input your university"
        />
      </Modal>
    </ContentWrapper>
  );
}

export default CrawlingManagement;
