import { createContext, useContext, useEffect, useState } from "react";
import { majorService } from "../../services";

const MajorsContext = createContext();

export const MajorsProvider = ({ children }) => {
  //**** STATE ***//
  const [majors, setMajors] = useState([]);

  const fetchMajors = async () => {
    try {
      const cacheMajors = sessionStorage.getItem("majors");
      if (cacheMajors) {
        setMajors(JSON.parse(cacheMajors));
        return;
      }
      const response = await majorService.getMajors();
      if (response) {
        sessionStorage.setItem("majors", JSON.stringify(response.data));
        setMajors(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchMajors();
  }, []);

  return (
    <MajorsContext.Provider
      value={{
        majors,
      }}
    >
      {children}
    </MajorsContext.Provider>
  );
};

export const useMajors = () => {
  return useContext(MajorsContext);
};
