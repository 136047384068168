import { useState } from "react";

import { Flex, Form, Typography, notification } from "antd";

import { plusIcon } from "../../../../assets/icons";
import { Icon } from "../../../../common/components";
import Button, { BUTTON_TYPE } from "../../../../common/components/Button";
import SearchBar from "../../../../common/components/SearchBar";
import { checkHasChanges } from "../../../../common/utils/common";
import ContentWrapper from "../../components/ContentWrapper";
import { useAdvisors } from "../../hooks/useAdvisors";
import { useSessionsByAdvisor } from "../../hooks/useSessionsByAdvisor";
import AdvisorModal from "./AdvisorModal";
import CustomTable from "../Table";
import { advisorTableColumns } from "../Table/columns";
import { ADVISOR_TABLE_ACTIONS } from "../../../../common/constants/actions";

const { Title } = Typography;
function AdvisorManagement() {
  /** STATE */
  const {
    editingAdvisor,
    advisors,
    advisorLoading,
    keyword,
    pagination,
    handlePagination,
    handleOnSearch,
    handleCreateAdvisor,
    handleEditAdvisor,
    handleSelectAdvisor,
  } = useAdvisors();

  const {
    sessionsByDates,
    selectedDate,
    handleDateChange,
    handleYearMonthChange,
    handleOpenAdvisorSessions,
    handleAddSession,
    handleRemoveSession,
    handleSaveNewSessions,
  } = useSessionsByAdvisor();
  const [form] = Form.useForm();
  const [modal, setModal] = useState({
    isOpen: false,
    action: null,
  });
  const [openDrawer, setOpenDrawer] = useState(false);

  const getFieldsToCompare = () => {
    const fieldsToCompare = { ...editingAdvisor };
    delete fieldsToCompare.id;
    delete fieldsToCompare.isDeleted;
    delete fieldsToCompare.userType;
    delete fieldsToCompare.createdAt;
    delete fieldsToCompare.updatedAt;
    return fieldsToCompare;
  };
  /** HANDLER */
  const handleOpenAdvisorModal = (advisor, action) => {
    setModal({ isOpen: true, action });
    handleSelectAdvisor(advisor);

    switch (action) {
      case ADVISOR_TABLE_ACTIONS.CREATE_INFO:
        form.setFieldsValue({ sessionQuota: 1 });
        break;
      case ADVISOR_TABLE_ACTIONS.EDIT_INFO:
        form.setFieldsValue(advisor);
        break;
      case ADVISOR_TABLE_ACTIONS.TIME_SLOT:
        handleOpenAdvisorSessions(advisor);
        break;
      default:
        break;
    }
  };
  const handleCloseModal = () => {
    setModal({ isOpen: false });
    form.resetFields();
  };

  const handleOnSubmit = async (newValues) => {
    try {
      switch (modal.action) {
        case ADVISOR_TABLE_ACTIONS.CREATE_INFO:
          await handleCreateAdvisor(newValues);
          handleCloseModal();
          break;

        case ADVISOR_TABLE_ACTIONS.EDIT_INFO:
          if (!checkHasChanges(getFieldsToCompare(), newValues)) return;
          await handleEditAdvisor({ id: editingAdvisor.id, ...newValues });
          handleCloseModal();
          break;

        case ADVISOR_TABLE_ACTIONS.TIME_SLOT:
          await handleSaveNewSessions();
          handleCloseDrawer();
          break;

        default:
          break;
      }
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      if (error.status === 409) {
        notification["error"]({
          message: `${modal.action} failed!`,
          description: error.response.data.message,
        });
        return;
      }
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleCalendarValueChange = (value) => {
    handleDateChange(value);
    handleOpenDrawer();
  };
  const handlePanelChange = (date, mode) => {
    if (mode === "month") {
      handleYearMonthChange(date);
    }
  };
  return (
    <>
      <ContentWrapper>
        <Flex justify="space-between">
          <Title className="content__header" level={3}>
            Advisor
          </Title>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            icon={<Icon width={16} height={16} src={plusIcon} />}
            onClick={() =>
              handleOpenAdvisorModal(null, ADVISOR_TABLE_ACTIONS.CREATE_INFO)
            }
          >
            Advisors
          </Button>
        </Flex>

        <SearchBar
          placeholder="Search by phone number/ name"
          value={keyword}
          onSearch={handleOnSearch}
        />
        <CustomTable
          loading={advisorLoading}
          dataSource={advisors}
          pagination={pagination}
          columns={advisorTableColumns(handleOpenAdvisorModal)}
          onPagination={handlePagination}
        />
      </ContentWrapper>
      <AdvisorModal
        loading={advisorLoading}
        action={modal.action}
        form={form}
        open={modal.isOpen}
        openDrawer={openDrawer}
        selectedDate={selectedDate}
        sessionsByDates={sessionsByDates}
        onAddSession={handleAddSession}
        onRemoveSession={handleRemoveSession}
        onClose={handleCloseDrawer}
        onSelect={handleCalendarValueChange}
        onPanelChange={handlePanelChange}
        onCancel={handleCloseModal}
        onFinish={handleOnSubmit}
      />
    </>
  );
}

export default AdvisorManagement;
