import { Form, Input, Modal, Select } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import React Quill CSS
import { Button } from "../../../../../common/components";
import { BUTTON_TYPE } from "../../../../../common/components/Button";

function JobTrialModal({
  loading,
  formDisabled,
  companyLoading,
  companies,
  majors,
  form,
  action,
  open,
  onCancel,
  onFinish,
  onSearchCompany,
}) {
  const companiesOptions = companies.map((x) => ({
    label: x.englishName,
    value: x.id,
  }));

  const majorsOptions = majors.map((x) => ({
    label: x.major,
    value: x.id,
  }));

  return (
    <Modal
      width={700}
      title={action}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        disabled={formDisabled}
        form={form}
        onFinish={(value) => onFinish(value, action)}
      >
        <Form.Item
          name="company"
          label="Company"
          rules={[
            {
              required: true,
              message: "Please select company",
            },
          ]}
        >
          <Select
            placeholder="Start typing to search company by name/phone"
            showSearch
            filterOption={false}
            onSearch={onSearchCompany}
            optionFilterProp="label"
            loading={companyLoading}
            options={companiesOptions}
          />
        </Form.Item>
        <Form.Item
          name="majors"
          label="Majors"
          rules={[
            {
              required: true,
              message: "Please select majors",
            },
          ]}
        >
          <Select
            mode="multiple"
            maxCount={5}
            placeholder="Start typing to search major by"
            showSearch
            optionFilterProp="label"
            notFoundContent={"Major not found"}
            options={majorsOptions}
          />
        </Form.Item>

        <Form.Item
          name="englishTitle"
          label="English title"
          rules={[
            {
              required: true,
              message: "Please input job trial's English title!",
            },
          ]}
        >
          <Input placeholder="input job trial's English title" />
        </Form.Item>

        <Form.Item
          name="arabicTitle"
          label="Arabic title"
          rules={[
            {
              required: true,

              message: "Please input job trial's Arabic title!",
            },
          ]}
        >
          <Input placeholder="input job trial's Arabic title" />
        </Form.Item>

        <Form.Item
          name="englishDesc"
          label="English description"
          rules={[
            {
              required: true,
              message: "Please input job trial's English description!",
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="input job trial's English description"
          />
        </Form.Item>

        <Form.Item
          name="arabicDesc"
          label="Arabic description"
          rules={[
            {
              required: true,
              message: "Please input job trial's Arabic description!",
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="input job trial's Arabic description"
          />
        </Form.Item>

        <Form.Item
          name="englishDuration"
          label="English duration"
          rules={[
            {
              required: true,
              message: "Please input job trial's English duration!",
            },
          ]}
        >
          <Input placeholder="input job trial's English duration" />
        </Form.Item>
        <Form.Item
          name="arabicDuration"
          label="Arabic duration"
          rules={[
            {
              required: true,
              message: "Please input job trial's Arabic duration!",
            },
          ]}
        >
          <Input placeholder="input job trial's Arabic duration" />
        </Form.Item>

        <Form.Item
          name="englishResponsibilities"
          label="English responsibilities"
          rules={[
            {
              required: true,
              message: "Please input job trial's English responsibilities!",
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            placeholder="input job trial's English responsibilities"
          />
        </Form.Item>
        <Form.Item
          name="arabicResponsibilities"
          label="Arabic responsibilities"
          rules={[
            {
              required: true,
              message: "Please input job trial's Arabic responsibilities!",
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            placeholder="input job trial's Arabic responsibilities"
          />
        </Form.Item>

        <Form.Item
          name="englishOutcomes"
          label="English outcomes"
          rules={[
            {
              required: true,
              message: "Please input job trial's English outcomes!",
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="input job trial's English outcomes"
          />
        </Form.Item>
        <Form.Item
          name="arabicOutcomes"
          label="Arabic outcomes"
          rules={[
            {
              required: true,
              message: "Please input job trial's Arabic outcomes!",
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="input job trial's Arabic outcomes"
          />
        </Form.Item>
        {!formDisabled && (
          <Form.Item>
            <Button
              loading={loading}
              type={BUTTON_TYPE.PRIMARY}
              style={{ float: "inline-end" }}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default JobTrialModal;
