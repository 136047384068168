import { useState, useEffect } from "react";
import { productService } from "../../../services";
import { productsTableDataSourceMapper } from "../common/mapper";

export const useProducts = () => {
  //**** STATE ***//
  const [products, setProducts] = useState([]);
  const [productLoading, setProductLoading] = useState(true);
  const [editingProduct, setEditingProduct] = useState(null);
  const [needRefresh, setNeedRefresh] = useState(true);

  //**** HANDLER ***//

  const fetchProducts = async () => {
    try {
      setProductLoading(true);
      const response = await productService.getProducts();
      if (response) {
        setProducts(productsTableDataSourceMapper(response.data));
      }
    } catch (error) {
    } finally {
      setProductLoading(false);
    }
  };
  const handleEditProduct = async (product) => {
    try {
      setProductLoading(true);
      await productService.updateProduct(product);
      setNeedRefresh((prev) => !prev);
    } catch (error) {
      throw error;
    } finally {
      setProductLoading(false);
    }
  };
  const handleSelectProduct = (product) => {
    setEditingProduct(product);
  };
  useEffect(() => {
    fetchProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh]);

  return {
    products,
    productLoading,
    editingProduct,
    handleSelectProduct,
    handleEditProduct,
  };
};
