import { Input } from "antd";

import "./index.css";

const { Search } = Input;

function SearchBar({ value, placeholder, onSearch }) {
  return (
    <Search
      className="search__bar__wrapper"
      placeholder={placeholder}
      onSearch={onSearch}
      onChange={onSearch}
      size="large"
      value={value}
    />
  );
}

export default SearchBar;
